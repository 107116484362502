import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PageLayout = ({ children, userName }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <div className="flex h-screen">
      <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 md:hidden"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}
      <div className="flex flex-col flex-1 overflow-hidden">
        <Header
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
          userName={userName}
          openNotification={() => {}}
          navigate={navigate}
        />
        <div className="flex-1 p-4 md:p-8 overflow-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
