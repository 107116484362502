import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaMicrophone, FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import FAQ from '../components/FAQ';

const PricingPage = () => {
  const [isOpen, setIsOpen] = useState(false);


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };



  const [scrolled, setScrolled] = useState(false);

useEffect(() => {
  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);



  return (
    <div
      className="bg-cover bg-center min-h-screen"
      style={{ backgroundImage: 'url(/assets/images/pricing_page_bg.png)' }}
    >

<Helmet>
        <title>Reekap Pricing - Affordable Plans for Meeting Transcriptions</title>
        <meta
          name="description"
          content="Discover affordable plans for meeting transcription and summaries with Reekap. Choose from free, starter, or pro plans to fit your needs."
        />
        <meta
          name="keywords"
          content="Reekap pricing, transcription software cost, meeting transcription plans, affordable AI transcription, Reekap plans"
        />
        <meta property="og:title" content="Reekap Pricing - Affordable Plans for Meeting Transcriptions" />
        <meta property="og:description" content="Start with Reekap's free plan or choose a subscription to access advanced features. No credit card required." />
        <meta property="og:image" content="https://reekap.com/assets/images/herobgimage.png" />
        <meta property="og:url" content="https://www.reekap.com/pricing" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {/* Navigation Menu */}
      <div className={`fixed top-0 left-0 w-full z-50 transition-colors duration-300 ${scrolled ? 'bg-[#2563EB]' : 'bg-transparent'} backdrop-blur`}>
        <div className="max-w-7xl mx-auto flex items-center justify-between px-4 py-4">
          {/* Logo */}
          <Link to="/">
            <img src="/assets/images/footer_logo.png" alt="Reekap Logo" className="w-24 md:w-32" />
          </Link>

          {/* Hamburger Icon */}
          <div className="md:hidden z-50">
            <button
              onClick={toggleMenu}
              className={`${scrolled ? 'text-white' : 'text-[#2563EB]'} focus:outline-none`}
              aria-label="Toggle menu"
            >
              {isOpen ? (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              )}
            </button>
          </div>

          {/* Menu Links */}
          <div
            className={`${
              isOpen ? 'flex' : 'hidden'
            } md:flex flex-col md:flex-row items-center md:items-center absolute md:relative top-16 md:top-0 left-0 w-full md:w-auto bg-[#2c3e50] md:bg-transparent z-40 py-6 md:py-0 md:space-x-8`}
          >
        <Link
  to="/pricing"
  className={`${
    scrolled ? 'text-white' : 'text-[#2563EB]'
  } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
>
  Pricing
</Link>
<Link
  to="/faqs"
  className={`${
    scrolled ? 'text-white' : 'text-[#2563EB]'
  } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
>
  FAQs
</Link>
<Link
  to="/login"
  className={`${
    scrolled ? 'text-white' : 'text-[#2563EB]'
  } font-medium text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
>
  Login
</Link>
<Link
  to="/register"
  className={`${
    scrolled ? 'bg-white text-[#2563EB]' : 'bg-[#2563EB] text-white'
  } py-2 px-4 rounded-lg font-semibold text-base md:text-lg hover:bg-white hover:text-[#2563EB] transition-colors duration-300 md:ml-4`}
>
  Start for free
</Link>

          </div>
        </div>
      </div>

      {/* Adjust Hero Section Padding to Account for Fixed Navbar */}
      <div className="pt-16">
        {/* Pricing Section */}
        <div className="py-20 px-4">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-[#2C3E50] font-outfit text-[28px] md:text-[39px] font-bold text-left md:text-center mb-6 leading-tight">
              Start Free and Upgrade as You Grow
            </h2>
            <p className="text-[rgba(23,23,23,0.60)] text-left md:text-center font-outfit text-[20px] font-normal leading-[24px] mb-12">
              Begin with our forever-free plan and unlock more features as your needs grow.
              <br />
              No credit card required.
            </p>

            <div className="max-w-5xl mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Free Plan */}
                <div className="bg-[rgba(255,255,255,0.60)] rounded-[24px] shadow-md px-6 py-12 flex flex-col items-start">
                  <h3 className="text-[#171717] font-outfit text-[31px] font-medium leading-[37.2px] mb-1">
                    Free Plan
                  </h3>
                  <p className="text-[rgba(23,23,23,0.50)] text-[16px] font-outfit font-normal leading-[19.2px] mb-4">
                    Free plan with limited features
                  </p>
                  <div className="flex items-center text-[#000] mb-4">
                    <h4 className="text-[39px] font-bold font-outfit leading-[46.8px] mr-1">
                      $0
                    </h4>
                    <span className="text-[16px] font-normal leading-[19.2px]">Per Month</span>
                  </div>
                  <div className="flex items-center mb-4">
                    <div className="bg-[rgba(44,62,80,0.10)] rounded-lg p-2.5">
                      <FaMicrophone className="w-[18px] h-[18px] text-[rgba(4,8,21,0.50)]" />
                    </div>
                    <span className="text-[rgba(4,8,21,0.50)] text-[20px] font-outfit ml-2 leading-[24px]">
                      30 Recording Minutes
                    </span>
                  </div>
                  <Link to="/register" className="w-full">
                    <button className="bg-[#2563EB] text-white rounded-[8px] py-4 px-6 shadow-md font-outfit text-[16px] leading-[19.2px] w-full hover:bg-white hover:text-[#2563EB] transition-colors duration-300">
                      Get Started
                    </button>
                  </Link>
                </div>

                {/* Starter Plan */}
                <div className="bg-[rgba(255,255,255,0.60)] rounded-[24px] shadow-md px-6 py-12 flex flex-col items-start">
                  <h3 className="text-[#171717] font-outfit text-[31px] font-medium leading-[37.2px] mb-1">
                    Starter
                  </h3>
                  <p className="text-[rgba(23,23,23,0.50)] text-[16px] font-outfit font-normal leading-[19.2px] mb-4">
                    Starter plan with more features
                  </p>
                  <div className="flex items-center text-[#000] mb-4">
                    <h4 className="text-[39px] font-bold font-outfit leading-[46.8px] mr-1">
                      $9.99
                    </h4>
                    <span className="text-[16px] font-normal leading-[19.2px]">Per Month</span>
                  </div>
                  <div className="flex items-center mb-4">
                    <div className="bg-[rgba(44,62,80,0.10)] rounded-lg p-2.5">
                      <FaMicrophone className="w-[18px] h-[18px] text-[rgba(4,8,21,0.50)]" />
                    </div>
                    <span className="text-[rgba(4,8,21,0.50)] text-[20px] font-outfit ml-2 leading-[24px]">
                      600 Recording Minutes
                    </span>
                  </div>
                  <Link to="/register" className="w-full">
                    <button className="bg-[#2563EB] text-white rounded-[8px] py-4 px-6 shadow-md font-outfit text-[16px] leading-[19.2px] w-full hover:bg-white hover:text-[#2563EB] transition-colors duration-300">
                      Get Started
                    </button>
                  </Link>
                </div>

                {/* Pro Plan */}
                <div className="bg-[rgba(255,255,255,0.60)] rounded-[24px] shadow-md px-6 py-12 flex flex-col items-start">
                  <h3 className="text-[#171717] font-outfit text-[31px] font-medium leading-[37.2px] mb-1">
                    Pro
                  </h3>
                  <p className="text-[rgba(23,23,23,0.50)] text-[16px] font-outfit font-normal leading-[19.2px] mb-4">
                    Pro plan with all features
                  </p>
                  <div className="flex items-center text-[#000] mb-4">
                    <h4 className="text-[39px] font-bold font-outfit leading-[46.8px] mr-1">
                      $19.99
                    </h4>
                    <span className="text-[16px] font-normal leading-[19.2px]">Per Month</span>
                  </div>
                  <div className="flex items-center mb-4">
                    <div className="bg-[rgba(44,62,80,0.10)] rounded-lg p-2.5">
                      <FaMicrophone className="w-[18px] h-[18px] text-[rgba(4,8,21,0.50)]" />
                    </div>
                    <span className="text-[rgba(4,8,21,0.50)] text-[20px] font-outfit ml-2 leading-[24px]">
                      1500 Recording Minutes
                    </span>
                  </div>
                  <Link to="/register" className="w-full">
                    <button className="bg-[#2563EB] text-white rounded-[8px] py-4 px-6 shadow-md font-outfit text-[16px] leading-[19.2px] w-full hover:bg-white hover:text-[#2563EB] transition-colors duration-300">
                      Get Started
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <FAQ />

        {/* CTA Section */}
        <div className="bg-[#1E2D3C] py-20 px-4">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center md:justify-between">
        {/* Left Column */}
        <div className="md:w-1/2 text-center md:text-left mb-8 md:mb-0 md:mr-36">
          <h3 className="text-white font-outfit text-[20px] font-bold leading-[24px] mb-2">
          Transform How You Handle Meetings
          </h3>
          <h2 className="text-white font-outfit text-[28px] md:text-[39px] font-normal leading-[46.8px] mb-4">
          Join thousands of professionals who stopped worrying about missing important details.
          </h2>
          <p className="text-[rgba(243,244,246,0.60)] font-outfit text-[20px] font-normal leading-[24px] mb-8">
          Whether you're a new team member trying to catch up quickly, or a seasoned professional managing multiple meetings -<br className="hidden md:block" /> Reekap helps you stay on top of everything while remaining fully present in conversations.
          </p>
          <Link to="/register">
  <button className="bg-[#2563EB] text-white rounded-[30px] py-4 px-6 md:px-8 shadow-md font-outfit text-[16px] leading-[19.2px] hover:bg-white hover:text-[#2563EB] transition-colors duration-300">
    Start Today!
  </button>
</Link>

        </div>

        {/* Right Column */}
        <div className="md:w-1/2 flex justify-center md:justify-end">
          <img
            src="/assets/images/ctadashboardimage.png"
            alt="Dashboard Preview"
            className="max-w-full rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>

         {/* Footer Section */}
<div className="bg-[#F3F4F6] py-10">
  <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center md:items-start justify-between px-4 space-y-6 md:space-y-0">
    
    {/* Left Column: Logo */}
    <div className="w-full md:w-1/6 flex justify-center md:justify-start">
    <Link to="/">
  <img src="/assets/images/footer_logo.png" alt="Reekap Logo" className="w-[123px]" />
</Link>
    </div>

    {/* Middle Column: Links */}
    <div className="w-full md:w-2/3 flex flex-col items-center md:items-start md:ml-8">
    <div className="flex flex-wrap justify-center md:justify-start space-x-6 text-[#171717] font-outfit text-[16px] font-normal mb-4">
  <Link to="/pricing" className="hover:text-[#2563EB] transition-colors duration-300">Pricing</Link>
  <Link to="/faqs" className="hover:text-[#2563EB] transition-colors duration-300">FAQs</Link>
  <Link to="/login" className="hover:text-[#2563EB] transition-colors duration-300">Login</Link>
  <Link to="/register" className="hover:text-[#2563EB] transition-colors duration-300">Signup</Link>
</div>

      {/* Divider Line */}
      <div className="w-full h-[1px] bg-[#171717] mb-4"></div>
      <div className="flex flex-wrap justify-center md:justify-start space-x-6 text-[#171717] font-outfit text-[16px] font-normal">
  <Link to="/privacy" className="hover:text-[#2563EB] transition-colors duration-300">Privacy Policy</Link>
  <Link to="/terms" className="hover:text-[#2563EB] transition-colors duration-300">Terms & Conditions</Link>
</div>
    </div>

    {/* Right Column: Social Media Icons */}
    <div className="w-full md:w-1/6 flex justify-center md:justify-end space-x-4">
      <a href="https://facebook.com" aria-label="Facebook">
        <FaFacebookF className="text-[#171717] w-5 h-5" />
      </a>
      <a href="https://instagram.com" aria-label="Instagram">
        <FaInstagram className="text-[#171717] w-5 h-5" />
      </a>
    </div>
  </div>
</div>
      </div>
    </div>
  );
};

export default PricingPage;
