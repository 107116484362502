import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Navigate, useNavigate, Link } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useAuth from '../hooks/useAuth'



const VerifyPhone = () => {
  const [phone, setPhone] = useState('');
  const [confirmPhone, setConfirmPhone] = useState('');
  const [preferredChannel, setPreferredChannel] = useState('sms');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_URL

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleChange = (element, index) => {
    const value = element.value;
  
    // If the pasted value is more than one character, assume it's a paste action
    if (value.length > 1) {
      const newOtp = value.slice(0, 6).split('');
      setOtp(newOtp);
  
      // Automatically focus the last filled input
      const lastInput = document.querySelector(
        `input[name='otp'][maxlength='1']:nth-of-type(${newOtp.length})`
      );
      if (lastInput) lastInput.focus();
    } else if (!isNaN(value) && value !== '') {
      const newOtp = [...otp];
      newOtp[index] = value;
  
      // Update OTP state
      setOtp(newOtp);
  
      // Move focus to the next input
      if (index < otp.length - 1) {
        element.nextSibling && element.nextSibling.focus();
      }
    }
  };
  

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, 6);
    if (pasteData && pasteData.length === 6 && pasteData.match(/^\d+$/)) {
      const newOtp = pasteData.split('');
      setOtp(newOtp);
  
      // Focus on the last input field after pasting
      const lastInput = document.querySelector(`input[name='otp'][maxlength='1']:nth-of-type(${newOtp.length})`);
      if (lastInput) lastInput.focus();
    }
  };
  

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (otp[index] === '') {
        if (index > 0) {
          e.target.previousSibling && e.target.previousSibling.focus()
        }
      } else {
        const newOtp = [...otp]
        newOtp[index] = ''
        setOtp(newOtp)
      }
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setMessage('')
    setError('')

    if (!isAgreed) {
      setError('You must agree to receive text messages.')
      return
    }

    if (phone !== confirmPhone) {
      setError('Phone numbers do not match.');
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(`${API_BASE_URL}/api/auth/verify-phone`, {
        phone,
        preferredChannel,
        userId: user?.id,
      });

      if (response && response.data) {
        setMessage(response.data.message)
        setShowOtpSection(true)
      } else {
        setError('Unexpected response from the server.')
      }
      setIsLoading(false)
    } catch (err) {
      console.error('Error sending verification code:', err)
      setError(
        err.response?.data?.message || 'Failed to send verification code.'
      )
      setIsLoading(false)
    }
  };

  const onOtpSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous errors
  
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/verify-phone-otp`, {
        otp: otp.join(''),
        userId: user?.id,
      });
  
      if (response && response.data) {
        setMessage(response.data.message);
        login(response.data);
        navigate('/dashboard');
      } else {
        setError('Unexpected response from the server.');
      }
    } catch (err) {
      console.error('Error verifying OTP:', err);
  
      if (err.response?.status === 400) {
        setError('The OTP you entered is incorrect. Please try again.');
      } else if (err.response?.status === 404) {
        setError('User not found or OTP expired. Please request a new code.');
      } else {
        setError('Failed to verify phone number. Please try again later.');
      }
  
      setMessage('');
    }
  };
  

  if (!user?.id) {
    return <Navigate to='/register' />
  }

  // Redirect to email verification if email is not verified
  if (user?.id && !user?.isEmailVerified) {
    return <Navigate to='/verify-email' />
  }
  if (user?.id && user?.isPhoneVerified) {
    return <Navigate to='/dashboard' />
  }

  

  return (
    <div className='bg-gray-100 min-h-screen'>
      {/* Navigation Menu */}
      <div
        className={`fixed top-0 left-0 w-full z-50 py-0 transition-colors duration-300 ${
          scrolled ? 'bg-[#2563EB]' : 'bg-transparent'
        } backdrop-blur`}
      >
        <div className='max-w-7xl mx-auto flex items-center justify-between px-4 py-4'>
          <Link to='/'>
            <img
              src='/assets/images/footer_logo.png'
              alt='Reekap Logo'
              className='w-24 md:w-32'
            />
          </Link>

          <div className='md:hidden z-50'>
            <button
              onClick={toggleMenu}
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } focus:outline-none`}
              aria-label='Toggle menu'
            >
              {isOpen ? (
                <svg
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              ) : (
                <svg
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 6h16M4 12h16m-7 6h7'
                  />
                </svg>
              )}
            </button>
          </div>

          <div
            className={`${
              isOpen ? 'flex' : 'hidden'
            } md:flex flex-col md:flex-row items-center md:items-center absolute md:relative top-16 md:top-0 left-0 w-full md:w-auto bg-[#2c3e50] md:bg-transparent z-40 py-6 md:py-0 md:space-x-8`}
          >
            <Link
              to='/pricing'
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
            >
              Pricing
            </Link>
            <Link
              to='/faqs'
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
            >
              FAQs
            </Link>
            <Link
              to='/login'
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-medium text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
            >
              Login
            </Link>
            <Link
              to='/register'
              className={`${
                scrolled ? 'bg-white text-[#2563EB]' : 'bg-[#2563EB] text-white'
              } py-2 px-4 rounded-lg font-semibold text-base md:text-lg hover:bg-white hover:text-[#2563EB] transition-colors duration-300 md:ml-4`}
            >
              Start for free
            </Link>
          </div>
        </div>
      </div>

      <div className='flex h-[100vh] items-center justify-center bg-gray-100 w-full px-4 md:px-0'>
        <div className='bg-white border shadow-lg rounded-lg flex flex-col items-center w-full sm:w-3/4 md:w-1/2 lg:w-1/3 p-4 md:p-8'>
          <div className='p-4 sm:p-6 md:p-8 w-full'>
            <h1 className='text-xl sm:text-2xl md:text-3xl font-bold text-center text-gray-800 mb-4'>
              Verify Phone
            </h1>
            <p className='text-center text-gray-600 mb-4 md:mb-6'>
              Enter your phone number to receive a verification code.
            </p>

            {error && (
              <div className='bg-red-100 text-red-700 p-2 rounded-md mb-4 text-center w-full pt-16'>
                {error}
              </div>
            )}
            {message && (
              <div className='bg-green-100 text-green-700 p-2 rounded-md mb-4 text-center w-full'>
                {message}
              </div>
            )}

<form onSubmit={onSubmit} className="space-y-4 md:space-y-6 w-full max-w-md mx-auto mb-4">
<PhoneInput
  country={'us'}
  value={phone}
  onChange={(phone) => setPhone(phone)}
  inputClass="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
  containerClass="w-full"
  inputStyle={{ fontSize: '16px', height: '45px' }} // Larger input size
  preferredCountries={['ca', 'us', 'ng', 'gb', 'de']}
  enableSearch={true}
  searchPlaceholder="Search your country"
/>

<PhoneInput
  country={'us'}
  value={confirmPhone}
  onChange={(phone) => setConfirmPhone(phone)} // Correctly update the confirmPhone state
  inputClass="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
  containerClass="w-full"
  inputStyle={{ fontSize: '16px', height: '45px' }} // Larger input size
  preferredCountries={['ca', 'us', 'ng', 'gb', 'de']}
  enableSearch={true}
  searchPlaceholder="Search your country"
/>


  <div style={{ color: 'red', display: phone && confirmPhone && phone !== confirmPhone ? 'block' : 'none' }}>
    Phone numbers do not match. Please try again.
  </div>

  <div className="flex flex-col space-y-2">
  <p className="font-semibold text-gray-700">How would you like to receive the OTP?</p>
  <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0">
    <label className="flex items-center space-x-2 p-2 border border-gray-300 rounded-md cursor-pointer hover:bg-blue-50 transition">
      <input
        type="radio"
        name="preferredChannel"
        value="sms"
        checked={preferredChannel === 'sms'}
        onChange={() => setPreferredChannel('sms')}
        className="cursor-pointer"
      />
      <span className="text-gray-600">SMS</span>
    </label>

      {/* Commented out the WhatsApp and Both options */}
    {/*
    <label className="flex items-center space-x-2 p-2 border border-gray-300 rounded-md cursor-pointer hover:bg-blue-50 transition">
      <input
        type="radio"
        name="preferredChannel"
        value="whatsapp"
        checked={preferredChannel === 'whatsapp'}
        onChange={() => setPreferredChannel('whatsapp')}
        className="cursor-pointer"
      />
      <span className="text-gray-600">WhatsApp</span>
    </label>
    <label className="flex items-center space-x-2 p-2 border border-gray-300 rounded-md cursor-pointer hover:bg-blue-50 transition">
      <input
        type="radio"
        name="preferredChannel"
        value="both"
        checked={preferredChannel === 'both'}
        onChange={() => setPreferredChannel('both')}
        className="cursor-pointer"
      />
      <span className="text-gray-600">Both</span>
    </label>*/}
  </div>
</div>


  <div className="flex items-center">
    <input
      type="checkbox"
      id="agree"
      className="mr-2"
      checked={isAgreed}
      onChange={(e) => setIsAgreed(e.target.checked)}
      required
    />
    <label htmlFor="agree" className="text-gray-600">
      I agree to receive text messages from Reekap by RavenZone.
    </label>
  </div>

  <div className="flex justify-center">
    <button
      className="w-full md:w-auto px-6 bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      type="submit"
      disabled={isLoading || phone !== confirmPhone}
    >
      {isLoading ? (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
        </div>
      ) : (
        'Send Verification Code'
      )}
    </button>
  </div>
</form>



            {showOtpSection && (
              <div className='mt-4 md:mt-8 w-full'>
                <p className='text-center text-gray-600 mb-4'>
                  Enter the OTP sent to your phone.
                </p>
                <form
                  onSubmit={onOtpSubmit}
                  className='space-y-4 md:space-y-6 w-full'
                >
                  <div className='flex justify-center space-x-2'>
                    {otp.map((data, index) => (
                      <input
                        key={index}
                        className='w-8 md:w-10 h-8 md:h-10 text-center text-xl font-semibold border-2 border-blue-500 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                        type='text'
                        name='otp'
                        maxLength='1'
                        value={data}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={(e) => handlePaste(e)}
                        onFocus={(e) => e.target.select()}
                        required
                      />
                    ))}
                  </div>
                  <div className='flex justify-center'>
                    <button
                      className='w-full md:w-auto px-6 bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
                      type='submit'
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <div className='flex justify-center items-center'>
                          <div className='animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white'></div>
                        </div>
                      ) : (
                        'Verify OTP'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyPhone
