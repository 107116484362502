import React, { useCallback, useState, useEffect } from 'react'
import PageLayout from '../components/PageLayout'; 
import axios from 'axios'
import SummarySection from '../components/SummarySection'
import LoadingSpinner from '../components/LoadingSpinner'
import {
  AiOutlineFileWord,
  AiOutlineFilePdf,
  AiOutlineMail,
  AiOutlineCopy,
} from 'react-icons/ai'
import { jsPDF } from 'jspdf'
import { Document, Packer, Paragraph } from 'docx'
import { saveAs } from 'file-saver'
import { getAudioDuration } from '../utils/secondsToMinutes'
import { Link } from 'react-router-dom'
import useAuth from '../hooks/useAuth';

const UploadAudio = () => {
  const { user } = useAuth(); // Access user data
  const userEmail = user?.email; // Get user email from context
  const [, setFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [isLimitLoading, setIsLimitLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [userLimit, setUserLimit] = useState(0) // User's allowed time in seconds
  const [usedSeconds, setUsedSeconds] = useState(0)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [results, setResults] = useState({
    general: '',
    minutes: '',
    simple: '',
  })
  const [summariesGenerated, setSummariesGenerated] = useState(false)

  const limitReached = usedSeconds >= userLimit


  const fetchLimits = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usage`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )

      const data = await response.data
      const { currentPeriodUsage, allowedLimit } = data.data

      setUserLimit(allowedLimit)
      setUsedSeconds(Number(currentPeriodUsage))
    } catch (error) {
      console.error('Error fetching usage limits:', error)
      setErrorMsg('Unable to fetch usage limits. Please try again.')
    }
  }, [])

  useEffect(() => {
    setIsLimitLoading(true)
    fetchLimits()
    setIsLimitLoading(false)
    // eslint-disable-next-line
  }, [])

  const handleFileUpload = async (event) => {
    setErrorMsg('');
    setIsLoading(true);
    try {
      await fetchLimits();
      if (limitReached) return;
  
      const uploadedFile = event.target.files[0];
      if (uploadedFile) {
        setFile(uploadedFile);
        const duration = await getAudioDuration(uploadedFile);
        if (Number(duration) + usedSeconds >= userLimit) {
          setErrorMsg('Uploaded audio duration exceeds available minutes.');
          return;
        }
        await uploadFile(uploadedFile, duration);
      }
    } catch (error) {
      console.error('Error handling file upload:', error);
      setErrorMsg('Failed to upload file. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const uploadFile = async (file, duration) => {
    setErrorMsg('');
    const formData = new FormData();
    formData.append('file', file);
    formData.append('duration', duration);
    formData.append('sendEmail', true); // Automatically set this to true
    formData.append('email', userEmail); // Include user's email directly from context
  
   
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/upload/upload-file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );
  
      setResults({
        general: response.data.generalSummary || '',
        minutes: response.data.meetingMinutes || '',
        simple: response.data.simpleSummary || '',
      });
      setSummariesGenerated(true);
      alert('File uploaded and processed successfully. An email with the summary has been sent.');
    } catch (error) {
      console.error('Error uploading file:', error);
      setErrorMsg(
        error.response?.data?.message ||
          'Failed to upload file. Please try again.'
      );
    }
  };
  


  const exportActions = () => (
    <>
      <button
        onClick={() => exportAsWord()}
        className='bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600'
        title='Export as Word'
      >
        <AiOutlineFileWord size={24} />
      </button>
      <button
        onClick={() => exportAsPDF()}
        className='bg-red-500 text-white p-2 rounded-lg hover:bg-red-600'
        title='Export as PDF'
      >
        <AiOutlineFilePdf size={24} />
      </button>
      <button
        onClick={() => sendEmailManual()}
        className='bg-orange-500 text-white p-2 rounded-lg hover:bg-orange-600'
        title='Email'
      >
        <AiOutlineMail size={24} />
      </button>
      <button
        onClick={() => copyToClipboard()}
        className='bg-green-500 text-white p-2 rounded-lg hover:bg-green-600'
        title='Copy'
      >
        <AiOutlineCopy size={24} />
      </button>
    </>
  )

  const exportAsWord = () => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({ text: 'General Summary' }),
            new Paragraph({ text: results.general }),
            new Paragraph({ text: 'Meeting Minutes' }),
            new Paragraph({ text: results.minutes }),
            new Paragraph({ text: 'Simple Summary' }),
            new Paragraph({ text: results.simple }),
          ],
        },
      ],
    })

    Packer.toBlob(doc).then((blob) => saveAs(blob, 'meeting_summary.docx'))
  }

  const exportAsPDF = () => {
    const doc = new jsPDF()
    doc.text('General Summary', 10, 10)
    doc.text(results.general, 10, 20)
    doc.text('Meeting Minutes', 10, 100)
    doc.text(results.minutes, 10, 110)
    doc.text('Simple Summary', 10, 200)
    doc.text(results.simple, 10, 210)
    doc.save('meeting_summary.pdf')
  }

  const sendEmailManual = () => {
    const subject = encodeURIComponent('Meeting Summary')
    const body = encodeURIComponent(
      `General Summary:\n${results.general}\n\nMeeting Minutes:\n${results.minutes}\n\nSimple Summary:\n${results.simple}`
    )
    window.location.href = `mailto:?subject=${subject}&body=${body}`
  }

  const copyToClipboard = () => {
    const text = `General Summary:\n${results.general}\n\nMeeting Minutes:\n${results.minutes}\n\nSimple Summary:\n${results.simple}`
    navigator.clipboard
      .writeText(text)
      .then(() => alert('Copied to clipboard!'))
  }

  return (
    <PageLayout>
      {isLimitLoading ? (
        ''
      ) : (
        <>
          {limitReached ? (
            <div className='bg-red-100 text-red-700 border border-red-400 px-4 py-3 flex flex-col sm:flex-row gap-4 justify-between items-start sm:items-center'>
              You have used up your available minutes. Please upgrade to
              continue.
              <Link
                to='/plans'
                className='bg-red-500 text-white px-2.5 py-2 rounded'
              >
                Upgrade
              </Link>
            </div>
          ) : errorMsg ? (
            <div className='bg-red-100 text-red-700 border border-red-400 px-4 py-3 mt-4'>
              {errorMsg}
            </div>
          ) : (
            ''
          )}

          <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4'>
            <h1 className='text-3xl font-bold mb-6'>
              Upload and Transcribe Audio
            </h1>
            <input
              type='file'
              onChange={handleFileUpload}
              disabled={limitReached || isLimitLoading}
              className='mb-4 text-lg cursor-pointer'
            />
            {isLoading && <LoadingSpinner />}
            <progress
              value={uploadProgress}
              max='100'
              className='w-full mb-4'
            />
            {summariesGenerated && (
              <>
                <div className='summary-results space-y-8 mt-8 w-full max-w-4xl'>
                  <SummarySection
                    title='General Summary'
                    content={results.general}
                  />
                  <SummarySection
                    title='Meeting Minutes'
                    content={results.minutes}
                  />
                  <SummarySection
                    title='Simple Summary'
                    content={results.simple}
                  />
                  <div className='export-actions flex justify-center space-x-4 mb-8'>
                    {exportActions()}
                    </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </PageLayout>
  );
};

export default UploadAudio;