import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaMicrophone, FaPlay, FaFileAudio, FaFileAlt, FaClipboard, FaFacebookF, FaInstagram} from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import FAQ from '../components/FAQ';

const LandingPage = () => {
  const [isOpen, setIsOpen] = useState(false);


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  
const [scrolled, setScrolled] = useState(false);

useEffect(() => {
  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);

     
    
  return (
    <div className="bg-gray-100">

      <Helmet>
        <title>Reekap - AI-Powered Meeting Transcriptions & Summaries</title>
        <meta
          name="description"
          content="Turn your meetings into minutes and smart summaries with Reekap. Preserve data privacy while capturing every detail effortlessly."
        />
        <meta
          name="keywords"
          content="meeting transcription, automated transcription, AI meeting summaries, Zoom recording, Google Meet transcription"
        />
        <meta property="og:title" content="Reekap - AI-Powered Meeting Transcriptions & Summaries" />
        <meta property="og:description" content="Reekap helps you transcribe, record, and summarize meetings effortlessly. Try it for free today!" />
        <meta property="og:image" content="https://reekap.com/assets/images/herobgimage.png" />
        <meta property="og:url" content="https://www.reekap.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {/* Hero with Background Image */}
      <div
        className="bg-cover bg-center h-auto md:min-h-screen flex flex-col"
        style={{ backgroundImage: 'url(/assets/images/herobgimage.png)' }}
      >
        {/* Navigation Menu */}
        <div className={`fixed top-0 left-0 w-full z-50 py-4 transition-colors duration-300 ${scrolled ? 'bg-[#2563EB]' : 'bg-transparent'} backdrop-blur`}>

          <div className="max-w-7xl mx-auto flex items-center justify-between px-4">
            {/* Logo */}
            <Link to="/">
  <img src="/assets/images/reekap_logo.png" alt="Reekap Logo" className="w-24 md:w-32" />
</Link>


            {/* Hamburger Icon */}
            <div className="md:hidden z-50">
              <button
                onClick={toggleMenu}
                className="text-white focus:outline-none"
                aria-label="Toggle menu"
              >
                {isOpen ? (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                  </svg>
                )}
              </button>
            </div>

            {/* Menu Links */}
            <div
              className={`${
                isOpen ? 'flex' : 'hidden'
              } md:flex flex-col md:flex-row items-center md:items-center absolute md:relative top-16 md:top-0 left-0 w-full md:w-auto bg-[#2c3e50] md:bg-transparent z-40 py-6 md:py-0 md:space-x-8`}
            >
              <Link
                to="/pricing"
                className="text-white font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200"
              >
                Pricing
              </Link>
              <Link
                to="/faqs"
                className="text-white font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200"
              >
                FAQs
              </Link>
              <Link
  to="/login"
  className={`${
    scrolled ? 'text-white' : 'text-[#2563EB]'
  } font-medium text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
>
  Login
</Link>
              <Link
  to="/register"
  className="bg-[#2563EB] text-white py-2 px-4 rounded-lg font-semibold text-base md:text-lg hover:bg-white hover:text-[#2563EB] transition-colors duration-300 md:ml-4"
>
  Start for free
</Link>
            </div>
          </div>
        </div>

        {/* Hero Content */}
        <div className="flex-grow flex items-center justify-center px-4 mt-24 md:mt-20 lg:mt-36">
          <div className="max-w-7xl mx-auto text-left md:text-center">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-6 md:mb-8 leading-tight">
            Never Miss a Meeting Detail Again
            </h1>
            <p className="text-base md:text-lg lg:text-xl text-white/50 mb-6 md:mb-8 font-outfit leading-6 md:leading-8 lg:leading-[30px] max-w-lg md:max-w-2xl mx-auto">
            Turn your meetings into detailed minutes and smart summaries. <br/>Your data stays on your device.
            </p>

            {/* CTA Buttons with Icons */}
            <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4 mt-6">
              <Link
                 to="/register"
                className="flex items-center justify-center border border-white text-white text-base md:text-lg font-outfit py-2 md:py-3 px-4 md:px-6 rounded-lg leading-[24px] hover:bg-white hover:text-[#2c3e50] transition duration-300"
              >
                <FaMicrophone className="w-5 h-5 mr-2" /> Start Recording now
              </Link>
              <Link
  to="/watch-demo"
  className="flex items-center justify-center bg-[#2563EB] text-white text-base md:text-lg font-outfit py-2 md:py-3 px-4 md:px-6 rounded-lg leading-[24px] hover:bg-white hover:text-[#2563EB] transition-colors duration-300"
>
  <FaPlay className="w-5 h-5 mr-2" /> Watch Demo
</Link>

            </div>
          </div>
        </div>

        {/* App Dashboard Image */}
        <div className="max-w-7xl mx-auto mt-2 md:mt-16 lg:mt-20 px-4">
          <img
            src="/assets/images/app_dashboard_mobile.png"
            alt="Reekap in action"
            className="block md:hidden w-full rounded-lg shadow-lg mt-8"
          />
          <img
            src="/assets/images/app_dashboard.png"
            alt="Reekap in action"
            className="hidden md:block w-full rounded-lg shadow-lg"
          />
        </div>
      </div>

{/* Why Reekap Section */}
<div className="bg-[#F3F4F6] pt-20 pb-8 px-4 md:px-0">
  <div className="max-w-7xl mx-auto">
    {/* First Div: Two-column layout */}
    <div className="flex flex-col md:flex-row md:space-x-8 mb-12 md:justify-center md:items-center">
      <div className="md:w-1/3 text-left mb-4 md:mb-0">
        <h2 className="text-[#2C3E50] font-outfit text-lg md:text-xl font-normal mb-2">
          Why Reekap?
        </h2>
        <h3 className="text-[#171717] font-outfit text-3xl md:text-[39px] font-bold leading-tight">
        Intuitive, Effective, <br className="hidden md:block" /> Meeting Assistant
        </h3>
      </div>
      <div className="md:w-2/3 text-left">
        <p className="text-[rgba(23,23,23,0.60)] text-base md:text-lg lg:text-[20px] font-['Plus Jakarta Sans'] font-normal leading-[1.5] tracking-[-0.4px]">
        Focus on the conversation while Reekap handles the documentation. Whether you're a new team member catching up or a busy professional managing multiple meetings, we've got you covered.
        </p>
      </div>
    </div>

    {/* Adding margin between the two sections on desktop */}
    <div className="mb-8 md:mb-24"></div>

    {/* Second Div: Three-column layout with consistent image sizes */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 relative">
      {/* Data Privacy Card */}
      <div className="flex flex-col text-left items-start px-4 md:px-0">
        <div className="w-full h-[300px] flex justify-center md:justify-start items-center mb-4">
          <img
            src="/assets/images/data_privacy.png"
            alt="Data Privacy"
            className="rounded-[12px] shadow-md w-full h-full object-cover"
          />
        </div>
        <h4 className="text-[#040815] font-['Plus Jakarta Sans'] text-[24px] font-semibold leading-[36px] tracking-[-0.72px] mb-2 text-left">
          Data Privacy
        </h4>
        <p className="text-[#596780] font-['Plus Jakarta Sans'] text-[16px] font-normal leading-[24px] tracking-[-0.32px] text-left">
          Your meeting info is not stored anywhere in our system...
        </p>
      </div>

      {/* Discreet Card */}
      <div className="flex flex-col text-left items-start px-4 md:px-0">
        <div className="w-full h-[300px] flex justify-center md:justify-start items-center mb-4">
          <img
            src="/assets/images/discreet.png"
            alt="Discreet"
            className="rounded-[10px] shadow-md w-full h-full object-cover"
          />
        </div>
        <h4 className="text-[#040815] font-['Plus Jakarta Sans'] text-[24px] font-semibold leading-[36px] tracking-[-0.72px] mb-2 text-left">
          Discreet
        </h4>
        <p className="text-[#596780] font-['Plus Jakarta Sans'] text-[16px] font-normal leading-[24px] tracking-[-0.32px] text-left">
          Your notes taking are discreet. No one else knows you're taking notes.
        </p>
      </div>

      {/* No Browser Limits Card with transform */}
      <div className="flex flex-col text-left items-start px-4 md:px-0 md:transform md:-translate-y-12">
        <div className="w-full h-[350px] flex justify-center md:justify-start items-center mb-4">
          <img
            src="/assets/images/nblimits.png"
            alt="No Browser Limits"
            className="rounded-[12px] shadow-md w-full h-full object-cover"
          />
        </div>
        <h4 className="text-[#040815] font-['Plus Jakarta Sans'] text-[24px] font-semibold leading-[36px] tracking-[-0.72px] mb-2 text-left">
          No Browser Limits
        </h4>
        <p className="text-[#596780] font-['Plus Jakarta Sans'] text-[16px] font-normal leading-[24px] tracking-[-0.32px] text-left">
          Can be used across all browsers.
        </p>
      </div>
    </div>
  </div>
</div>


{/* What you Get Section */}
<div className="bg-[#F3F4F6] pt-8 pb-20 px-4">
  <div className="max-w-7xl mx-auto">
    {/* Header */}
    <h2 className="text-[#2C3E50] font-outfit text-[28px] md:text-[39px] font-bold text-left md:text-center mb-12 leading-tight">
      What You Get with Reekap<br className="hidden md:block" /> — For Free
    </h2>

    {/* Two-column layout */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center md:gap-x-[100px]">
      {/* Left Column: Feature List with gap */}
      <div className="flex flex-col space-y-11 md:space-y-[44px]">
        {/* Record Meetings */}
        <div className="flex items-start space-x-4">
          <div className="bg-[#2563EB] p-3 rounded-[12px]">
            <FaMicrophone className="text-white w-4 h-4 md:w-6 md:h-6" />
          </div>
          <div>
            <h4 className="text-[#040815] font-outfit text-[25px] font-bold leading-[30px]">
              Record Meetings
            </h4>
            <p className="text-[#596780] font-outfit text-[16px] font-normal leading-[24px]">
              Capture meetings discreetly from any device - perfect for when you need to reference discussions later. Use a separate device for full conversation capture, or wait for our upcoming browser extension.
            </p>
          </div>
        </div>

        {/* Transcribe Audio */}
        <div className="flex items-start space-x-4">
          <div className="bg-[#2563EB] p-3 rounded-[12px]">
            <FaFileAudio className="text-white w-4 h-4 md:w-6 md:h-6" />
          </div>
          <div>
            <h4 className="text-[#040815] font-outfit text-[25px] font-bold leading-[30px]">
              Transcribe Audio
            </h4>
            <p className="text-[#596780] font-outfit text-[16px] font-normal leading-[24px]">
            Convert conversations into searchable text with industry-leading accuracy. Perfect for quick reference and knowledge sharing.
            </p>
          </div>
        </div>

        {/* Generate Summaries */}
        <div className="flex items-start space-x-4">
          <div className="bg-[#2563EB] p-3 rounded-[12px]">
            <FaFileAlt className="text-white w-4 h-4 md:w-6 md:h-6" />
          </div>
          <div>
            <h4 className="text-[#040815] font-outfit text-[25px] font-bold leading-[30px]">
              Generate Summaries
            </h4>
            <p className="text-[#596780] font-outfit text-[16px] font-normal leading-[24px]">
            Get smart, AI-generated summaries that highlight key points and action items. Plus, receive simplified explanations of complex topics - ideal for new team members.
            </p>
          </div>
        </div>

        {/* Create Meeting Minutes */}
        <div className="flex items-start space-x-4">
          <div className="bg-[#2563EB] p-3 rounded-[12px]">
            <FaClipboard className="text-white w-4 h-4 md:w-6 md:h-6" />
          </div>
          <div>
            <h4 className="text-[#040815] font-outfit text-[25px] font-bold leading-[30px]">
              Create Meeting Minutes
            </h4>
            <p className="text-[#596780] font-outfit text-[16px] font-normal leading-[24px]">
            Automatically generate structured meeting minutes with key decisions, action items, and important discussions - no manual note-taking required.
            </p>
          </div>
        </div>
      </div>

      {/* Right Column: Image */}
      <div className="flex justify-center md:justify-end mt-8 md:mt-0">
        <img
          src="/assets/images/whatyougetimage.png"
          alt="What You Get"
          className="rounded-lg shadow-md max-w-full md:max-w-lg"
        />
      </div>
    </div>
  </div>
</div>



  {/* How it Works Section */}
  <div
        className="bg-cover bg-center py-20 md:py-28 lg:py-36 px-4"
        style={{ backgroundImage: 'url(/assets/images/howitworksbgimage.png)' }}
      >
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-white text-[25.057px] font-outfit font-bold leading-[30.068px]">
            How Reekap Works
          </h2>
          {/* Centered Card Image */}
          <div className="flex justify-center mt-8">
            <img
              src="/assets/images/howitworkscardimage.png"
              alt="How Reekap Works"
              className="rounded-[15px] shadow-lg max-w-full"
            />
          </div>
        </div>
      </div>

 {/* Pricing Section */}
 <div className="bg-[#F3F4F6] py-20 px-4">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <h2 className="text-[#2C3E50] font-outfit text-[28px] md:text-[39px] font-bold text-left md:text-center mb-6 leading-tight">
        
          Start Free and Upgrade as You Grow
        </h2>
        <p className="text-[rgba(23,23,23,0.60)] text-left md:text-center font-outfit text-[20px] font-normal leading-[24px] mb-12">
          Begin with our forever-free plan and unlock more features as your needs grow.<br></br> No credit card required.
        </p>

        {/* Container for the Cards */}
        <div className="max-w-5xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Free Plan */}
            <div className="bg-[rgba(255,255,255,0.60)] rounded-[24px] shadow-md px-6 py-12 flex flex-col items-start">
              <h3 className="text-[#171717] font-outfit text-[31px] font-medium leading-[37.2px] mb-1">
                Free Plan
              </h3>
              <p className="text-[rgba(23,23,23,0.50)] text-[16px] font-outfit font-normal leading-[19.2px] mb-4">
                Free plan with limited features
              </p>
              <div className="flex items-center text-[#000] mb-4">
                <h4 className="text-[39px] font-bold font-outfit leading-[46.8px] mr-1">
                  $0
                </h4>
                <span className="text-[16px] font-normal leading-[19.2px]">
                  Per Month
                </span>
              </div>
              <div className="flex items-center mb-4 ">
              <div className="bg-[rgba(44,62,80,0.10)] rounded-lg p-2.5">
                <FaMicrophone className="w-[18px] h-[18px] text-[rgba(4,8,21,0.50)] " />
                </div>
                <span className="text-[rgba(4,8,21,0.50)] text-[20px] font-outfit ml-2 leading-[24px]">
                  30 Recording Minutes
                </span>
              </div>
              <Link to="/register" className="w-full">
  <button className="bg-[#2563EB] text-white rounded-[8px] py-4 px-6 shadow-md font-outfit text-[16px] leading-[19.2px] w-full hover:bg-white hover:text-[#2563EB] transition-colors duration-300">
    Get Started
  </button>
</Link>


            </div>

            {/* Starter Plan */}
            <div className="bg-[rgba(255,255,255,0.60)] rounded-[24px] shadow-md px-6 py-12 flex flex-col items-start">
              <h3 className="text-[#171717] font-outfit text-[31px] font-medium leading-[37.2px] mb-1">
                Starter
              </h3>
              <p className="text-[rgba(23,23,23,0.50)] text-[16px] font-outfit font-normal leading-[19.2px] mb-4">
                Starter plan with more features
              </p>
              <div className="flex items-center text-[#000] mb-4">
                <h4 className="text-[39px] font-bold font-outfit leading-[46.8px] mr-1">
                  $9.99
                </h4>
                <span className="text-[16px] font-normal leading-[19.2px]">
                  Per Month
                </span>
              </div>
              <div className="flex items-center mb-4">
              <div className="bg-[rgba(44,62,80,0.10)] rounded-lg p-2.5">
                <FaMicrophone className="w-[18px] h-[18px] text-[rgba(4,8,21,0.50)] " />
                </div>
                <span className="text-[rgba(4,8,21,0.50)] text-[20px] font-outfit ml-2 leading-[24px]">
                  600 Recording Minutes
                </span>
              </div>
              <Link to="/register" className="w-full">
  <button className="bg-[#2563EB] text-white rounded-[8px] py-4 px-6 shadow-md font-outfit text-[16px] leading-[19.2px] w-full hover:bg-white hover:text-[#2563EB] transition-colors duration-300">
    Get Started
  </button>
</Link>

            </div>

            {/* Pro Plan */}
            <div className="bg-[rgba(255,255,255,0.60)] rounded-[24px] shadow-md px-6 py-12 flex flex-col items-start">
              <h3 className="text-[#171717] font-outfit text-[31px] font-medium leading-[37.2px] mb-1">
                Pro
              </h3>
              <p className="text-[rgba(23,23,23,0.50)] text-[16px] font-outfit font-normal leading-[19.2px] mb-4">
                Pro plan with all features
              </p>
              <div className="flex items-center text-[#000] mb-4">
                <h4 className="text-[39px] font-bold font-outfit leading-[46.8px] mr-1">
                  $19.99
                </h4>
                <span className="text-[16px] font-normal leading-[19.2px]">
                  Per Month
                </span>
              </div>
              <div className="flex items-center mb-4">
              <div className="bg-[rgba(44,62,80,0.10)] rounded-lg p-2.5">
                <FaMicrophone className="w-[18px] h-[18px] text-[rgba(4,8,21,0.50)] " />
                </div>
                <span className="text-[rgba(4,8,21,0.50)] text-[20px] font-outfit ml-2 leading-[24px]">
                  1500 Recording Minutes
                </span>
              </div>
              <Link to="/register" className="w-full">
  <button className="bg-[#2563EB] text-white rounded-[8px] py-4 px-6 shadow-md font-outfit text-[16px] leading-[19.2px] w-full hover:bg-white hover:text-[#2563EB] transition-colors duration-300">
    Get Started
  </button>
</Link>

            </div>
          </div>
        </div>

        {/* Need More Minutes Section */}
        <div className="max-w-5xl mx-auto">
        <div className="flex flex-col md:flex-row md:items-center mt-12 gap-4">
          <div className="md:w-1/5 text-[#171717] font-outfit text-[31px] font-bold leading-[37.2px] text-left">
            Need More Minutes?
          </div>
          <div className="md:w-4/5 text-[rgba(23,23,23,0.60)] text-[20px] font-['Plus Jakarta Sans'] font-normal leading-[30px] tracking-[-0.4px]">
            For paid plans, we offer top-up options. If you run out of minutes before your billing cycle renews, you can purchase additional recording time at any point from your account settings.
          </div>
        </div>
        </div>
      </div>
    </div>

 {/* FAQ Section */}
 <FAQ />


 {/* CTA Section */}
 <div className="bg-[#1E2D3C] py-20 px-4">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center md:justify-between">
        {/* Left Column */}
        <div className="md:w-1/2 text-center md:text-left mb-8 md:mb-0 md:mr-36">
          <h3 className="text-white font-outfit text-[20px] font-bold leading-[24px] mb-2">
          Transform How You Handle Meetings
          </h3>
          <h2 className="text-white font-outfit text-[28px] md:text-[39px] font-normal leading-[46.8px] mb-4">
          Join thousands of professionals who stopped worrying about missing important details.
          </h2>
          <p className="text-[rgba(243,244,246,0.60)] font-outfit text-[20px] font-normal leading-[24px] mb-8">
          Whether you're a new team member trying to catch up quickly, or a seasoned professional managing multiple meetings -<br className="hidden md:block" /> Reekap helps you stay on top of everything while remaining fully present in conversations.
          </p>
          <Link to="/register">
  <button className="bg-[#2563EB] text-white rounded-[30px] py-4 px-6 md:px-8 shadow-md font-outfit text-[16px] leading-[19.2px] hover:bg-white hover:text-[#2563EB] transition-colors duration-300">
    Start Today!
  </button>
</Link>

        </div>

        {/* Right Column */}
        <div className="md:w-1/2 flex justify-center md:justify-end">
          <img
            src="/assets/images/ctadashboardimage.png"
            alt="Dashboard Preview"
            className="max-w-full rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>

     
     {/* Footer Section */}
<div className="bg-[#F3F4F6] py-10">
  <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center md:items-start justify-between px-4 space-y-6 md:space-y-0">
    
    {/* Left Column: Logo */}
    <div className="w-full md:w-1/6 flex justify-center md:justify-start">
    <Link to="/">
  <img src="/assets/images/footer_logo.png" alt="Reekap Logo" className="w-[123px]" />
</Link>
    </div>

    {/* Middle Column: Links */}
    <div className="w-full md:w-2/3 flex flex-col items-center md:items-start md:ml-8">
    <div className="flex flex-wrap justify-center md:justify-start space-x-6 text-[#171717] font-outfit text-[16px] font-normal mb-4">
  <Link to="/pricing" className="hover:text-[#2563EB] transition-colors duration-300">Pricing</Link>
  <Link to="/faqs" className="hover:text-[#2563EB] transition-colors duration-300">FAQs</Link>
  <Link to="/login" className="hover:text-[#2563EB] transition-colors duration-300">Login</Link>
  <Link to="/register" className="hover:text-[#2563EB] transition-colors duration-300">Signup</Link>
  <a 
          href="https://docs.google.com/forms/d/e/1FAIpQLSek71t9jRVnOrTlZGrdNz_5-orRXvhVvrU5f6_HIL012-43Vw/viewform?usp=sf_link" 
          target="_blank" 
          rel="noopener noreferrer"
          className="hover:text-[#2563EB] transition-colors duration-300"
        >
          Contact
        </a>
</div>

      {/* Divider Line */}
      <div className="w-full h-[1px] bg-[#171717] mb-4"></div>
      <div className="flex flex-wrap justify-center md:justify-start space-x-6 text-[#171717] font-outfit text-[16px] font-normal">
  <Link to="/privacy" className="hover:text-[#2563EB] transition-colors duration-300">Privacy Policy</Link>
  <Link to="/terms" className="hover:text-[#2563EB] transition-colors duration-300">Terms & Conditions</Link>
</div>
    </div>

    {/* Right Column: Social Media Icons */}
    <div className="w-full md:w-1/6 flex justify-center md:justify-end space-x-4">
      <a href="https://facebook.com" aria-label="Facebook">
        <FaFacebookF className="text-[#171717] w-5 h-5" />
      </a>
      <a href="https://instagram.com" aria-label="Instagram">
        <FaInstagram className="text-[#171717] w-5 h-5" />
      </a>
    </div>
  </div>
</div>


    </div>


    
  );
};

export default LandingPage;
