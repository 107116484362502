import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
<Helmet>
  <title>Reekap - Terms and Conditions</title>
  <meta
    name="description"
    content="Read the Terms and Conditions for using Reekap. Learn about our service policies, user responsibilities, account management, subscription plans, and more."
  />
  <meta
    name="keywords"
    content="Reekap terms, terms and conditions, user responsibilities, account terms, subscription plans, service policies"
  />
  <meta property="og:title" content="Reekap - Terms and Conditions" />
  <meta
    property="og:description"
    content="Understand the terms and conditions for using Reekap's meeting recording, transcription, and summarization services."
  />
  <meta property="og:image" content="https://reekap.com/assets/images/herobgimage.png" />
  <meta property="og:url" content="https://www.reekap.com/terms" />
  <meta name="twitter:card" content="summary_large_image" />
</Helmet>


      {/* Navigation Menu */}
      <div className={`fixed top-0 left-0 w-full z-50 py-0 transition-colors duration-300 ${scrolled ? 'bg-[#2563EB]' : 'bg-transparent'} backdrop-blur`}>
        <div className="max-w-7xl mx-auto flex items-center justify-between px-4 py-4">
          <Link to="/">
            <img src="/assets/images/footer_logo.png" alt="Reekap Logo" className="w-24 md:w-32" />
          </Link>
          <div className="md:hidden z-50">
            <button
              onClick={toggleMenu}
              className={`${scrolled ? 'text-white' : 'text-[#2563EB]'} focus:outline-none`}
              aria-label="Toggle menu"
            >
              {isOpen ? (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              )}
            </button>
          </div>

          <div className={`${isOpen ? 'flex' : 'hidden'} md:flex flex-col md:flex-row items-center md:items-center absolute md:relative top-16 md:top-0 left-0 w-full md:w-auto bg-[#2c3e50] md:bg-transparent z-40 py-6 md:py-0 md:space-x-8`}>
            <Link to="/pricing" className={`${scrolled ? 'text-white' : 'text-[#2563EB]'} font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}>
              Pricing
            </Link>
            <Link to="/faqs" className={`${scrolled ? 'text-white' : 'text-[#2563EB]'} font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}>
              FAQs
            </Link>
            <Link to="/login" className={`${scrolled ? 'text-white' : 'text-[#2563EB]'} font-medium text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}>
              Login
            </Link>
            <Link to="/register" className={`${scrolled ? 'bg-white text-[#2563EB]' : 'bg-[#2563EB] text-white'} py-2 px-4 rounded-lg font-semibold text-base md:text-lg hover:bg-white hover:text-[#2563EB] transition-colors duration-300 md:ml-4`}>
              Start for free
            </Link>
          </div>
        </div>
      </div>

      <div className="container mx-auto p-6 md:p-12 mt-20">
        <h1 className="text-3xl md:text-4xl font-extrabold text-center mb-6 text-gray-800">
          Terms and Conditions for Reekap
        </h1>
        <p className="text-sm md:text-base text-gray-600 mb-4 text-center">
          <strong>Effective Date:</strong> 15th October 2024
        </p>

        <div className="space-y-6">
          <section>
            <h2 className="text-xl font-semibold mb-2">1. Overview of Service</h2>
            <p>Reekap provides tools for meeting recording, transcription, and summaries. By using our services, you agree to these terms and acknowledge that Reekap does not store recordings or transcriptions.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">2. User Responsibility</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>You are solely responsible for storing and managing your organization's data, including recordings and transcriptions created through Reekap.</li>
              <li>By using Reekap, you consent to ensure that all necessary permissions for recording are obtained within your jurisdiction.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">3. Account Terms</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>You must provide accurate information during registration, including your legal full name and a valid email address.</li>
              <li>Your account is for individual use only and should not be shared with others.</li>
              <li>Reekap reserves the right to suspend or terminate accounts that engage in prohibited activities or violate these terms.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">4. Subscription Plans and Payments</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>Reekap offers free and paid plans, including options to purchase additional recording minutes if needed.</li>
              <li>All payments are non-refundable, including for partially used months or unused services.</li>
              <li>Price changes for plans will be notified in advance and will take effect in the next billing cycle.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">5. Limitation of Liability</h2>
            <p>Reekap is provided "as is" without warranties of any kind. Reekap is not liable for any direct or indirect damages arising from the use or inability to use the service, including loss of data or service interruptions. Users assume all risks associated with using Reekap.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">6. Termination of Service</h2>
            <p>You may terminate your account at any time, and Reekap reserves the right to terminate or restrict access for any violation of these terms. Users are responsible for backing up their data before account termination.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">7. Modifications to the Service</h2>
            <p>Reekap reserves the right to modify or discontinue the service, with or without notice, and is not liable for any changes or discontinuation of the service.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">8. Indemnification</h2>
            <p>You agree to indemnify and hold Reekap harmless from any claims, liabilities, or expenses arising from your use of the service, including any violations of these terms.</p>
          </section>

          <p className="text-sm text-gray-500">
            For questions or concerns, contact us at: <a href="mailto:admin@reekap.com" className="text-blue-500 hover:underline">admin@reekap.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
