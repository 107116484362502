import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      <Helmet>
  <title>Reekap - Privacy Policy</title>
  <meta
    name="description"
    content="Read the Privacy Policy of Reekap to understand how we collect, use, and protect your information. Learn about your rights and data security."
  />
  <meta
    name="keywords"
    content="Reekap privacy policy, data collection, user data security, user rights, data protection"
  />
  <meta property="og:title" content="Reekap - Privacy Policy" />
  <meta
    property="og:description"
    content="Understand how Reekap handles your data with our Privacy Policy. Learn about data security, retention, and your rights."
  />
  <meta property="og:image" content="https://reekap.com/assets/images/herobgimage.png" />
  <meta property="og:url" content="https://www.reekap.com/privacy" />
  <meta name="twitter:card" content="summary_large_image" />
</Helmet>

      {/* Navigation Menu */}
      <div className={`fixed top-0 left-0 w-full z-50 py-0 transition-colors duration-300 ${scrolled ? 'bg-[#2563EB]' : 'bg-transparent'} backdrop-blur`}>
        <div className="max-w-7xl mx-auto flex items-center justify-between px-4 py-4">
          <Link to="/">
            <img src="/assets/images/footer_logo.png" alt="Reekap Logo" className="w-24 md:w-32" />
          </Link>
          <div className="md:hidden z-50">
            <button
              onClick={toggleMenu}
              className={`${scrolled ? 'text-white' : 'text-[#2563EB]'} focus:outline-none`}
              aria-label="Toggle menu"
            >
              {isOpen ? (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              )}
            </button>
          </div>

          <div className={`${isOpen ? 'flex' : 'hidden'} md:flex flex-col md:flex-row items-center md:items-center absolute md:relative top-16 md:top-0 left-0 w-full md:w-auto bg-[#2c3e50] md:bg-transparent z-40 py-6 md:py-0 md:space-x-8`}>
            <Link to="/pricing" className={`${scrolled ? 'text-white' : 'text-[#2563EB]'} font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}>
              Pricing
            </Link>
            <Link to="/faqs" className={`${scrolled ? 'text-white' : 'text-[#2563EB]'} font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}>
              FAQs
            </Link>
            <Link to="/login" className={`${scrolled ? 'text-white' : 'text-[#2563EB]'} font-medium text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}>
              Login
            </Link>
            <Link to="/register" className={`${scrolled ? 'bg-white text-[#2563EB]' : 'bg-[#2563EB] text-white'} py-2 px-4 rounded-lg font-semibold text-base md:text-lg hover:bg-white hover:text-[#2563EB] transition-colors duration-300 md:ml-4`}>
              Start for free
            </Link>
          </div>
        </div>
      </div>

      <div className="container mx-auto p-6 md:p-12 mt-20">
        <h1 className="text-3xl md:text-4xl font-extrabold text-center mb-6 text-gray-800">
          Privacy Policy for Reekap
        </h1>
        <p className="text-sm md:text-base text-gray-600 mb-4 text-center">
          <strong>Effective Date:</strong> 15th October 2024
        </p>

        <div className="space-y-6">
          <section>
            <h2 className="text-xl font-semibold mb-2">1. Information We Collect</h2>
            <p>We collect various types of data when you interact with Reekap:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <strong>Personal Information:</strong> Includes your name, email address, and other information you provide during account registration.
              </li>
              <li>
                <strong>Usage Data:</strong> Data related to your use of our services, including the number of recorded minutes, interaction details, and analytics for improving service performance.
              </li>
              <li>
                <strong>Meeting Data:</strong> While Reekap processes meeting transcriptions and summaries, we do not store recordings. Only summary data and statistics are retained.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">2. How We Use Your Information</h2>
            <p>Your data is used to:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>Provide and maintain the Reekap service.</li>
              <li>Improve user experience by analyzing usage patterns and statistics.</li>
              <li>Send you updates regarding new features, service improvements, or policy changes.</li>
              <li>Ensure compliance with legal obligations and protect against fraud.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">3. Sharing and Disclosure of Information</h2>
            <p>We may share your data in the following situations:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>With service providers who help us deliver and improve the service (e.g., hosting and analytics services).</li>
              <li>In response to legal requests or compliance with legal obligations, such as court orders or governmental requests.</li>
              <li>During business transactions such as mergers or acquisitions, where your data may be transferred to new ownership.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">4. Security of Your Information</h2>
            <p>
              We implement appropriate security measures to protect your data from unauthorized access or disclosure. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security of your data.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">5. Data Retention</h2>
            <p>
              Personal Information is retained for as long as necessary to provide the service and fulfill the purposes outlined in this policy. Users can request account deletion and data removal at any time by contacting us.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">6. Your Rights</h2>
            <p>You have the right to:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>Access the data we store about you and request a copy.</li>
              <li>Request corrections to inaccurate or outdated information.</li>
              <li>Request deletion of your account and associated data.</li>
              <li>Opt out of certain data processing activities, such as marketing communications.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">7. Cookies and Tracking</h2>
            <p>
              Reekap may use cookies and similar tracking technologies to analyze trends and improve user experience. You can adjust your browser settings to manage cookies; however, disabling cookies may impact certain features of our service.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">8. Changes to This Privacy Policy</h2>
            <p>
              We may update this policy periodically. Any changes will be communicated through our website or via email. Your continued use of Reekap after such changes indicates your acceptance of the updated policy.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">9. Contact Us</h2>
            <p>
              If you have any questions or concerns about this Privacy Policy, please contact us at: <a href="mailto:admin@reekap.com" className="text-blue-500 hover:underline">admin@reekap.com</a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
