import React from 'react';

const NotificationModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 shadow-lg max-w-lg w-full max-h-full md:max-h-[80vh] overflow-y-auto">
        <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
          Optimize Your Meeting Recordings
        </h2>
        <p className="text-gray-700 text-base md:text-lg mb-4 leading-relaxed">
          For the best audio quality during virtual meetings (e.g., Teams, Zoom, Google Meet), we recommend using Reekap on a <strong>separate device</strong> from your meeting device.
        </p>
        <p className="text-gray-700 text-base md:text-lg mb-4 leading-relaxed">
          Here’s how:
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base md:text-lg mb-4">
          <li>Use Reekap on your <strong>phone</strong> while attending the meeting on your computer.</li>
          <li>Alternatively, use Reekap on a <strong>different computer</strong> or tablet than the one hosting your meeting.</li>
        </ul>
        <p className="text-gray-700 text-base md:text-lg mb-4 leading-relaxed">
          However, for an <strong>in-person meeting</strong>, feel free to use Reekap on any device you please.
        </p>
        <p className="text-gray-700 text-base md:text-lg mb-6 leading-relaxed">
          We're working on a <strong>Chrome extension</strong> that will allow recording directly from your meeting device. Stay tuned!
        </p>
        <button
          onClick={onClose}
          className="bg-blue-600 text-white text-base md:text-lg py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors"
        >
          Got it, thanks!
        </button>
      </div>
    </div>
  );
};

export default NotificationModal;
