import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Lottie from 'lottie-react'
import successLottie from '../assets/lottie/success-lottie.json'
import errorLottie from '../assets/lottie/error-lottie.json'

const SubscriptionSuccess = () => {
  const [loading, setLoading] = useState(true)
  const [, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const sessionId = searchParams.get('session_id')
  const paypalToken = searchParams.get('token')
  const redirect = searchParams.get('redirect')
  const type = searchParams.get('type')

  useEffect(() => {
    const checkStatus = async () => {
      if ((!sessionId && !paypalToken) || !redirect) {
        navigate('/dashboard')
        return
      }
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/payments/check-session-status`,
          { sessionId: sessionId || paypalToken, type },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
            },
          }
        )

        setSuccess(true)
        setLoading(false)
        setTimeout(() => navigate('/dashboard'), 5000)
      } catch (error) {
        setError(true)
        setLoading(false)
      }
    }

    checkStatus()

    // eslint-disable-next-line
  }, [sessionId])

  const proceedToDashboard = () => {
    navigate('/dashboard')
  }

  if (loading) {
    return (
      <div className='w-screen h-screen flex items-center justify-center'>
        <div className='loading-spinner'></div>
      </div>
    )
  }

  if (success) {
    return (
      <main className='w-screen h-screen mt-2 flex items-center justify-center'>
        <div className='w-[90%] max-w-2xl mx-auto flex flex-col gap-4 items-center justify-center'>
          <div className='w-40'>
            <Lottie animationData={successLottie} loop={false} />
          </div>
          <h4 className='text-lg sm:text-3xl'>Payment Successful</h4>
          <p className='text-lg text-center'>
            You will be redirected to your dashboard soon
          </p>
          <button
            className='mt-5 tracking-wide font-semibold bg-blue-900 text-gray-100 py-4 px-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none'
            onClick={proceedToDashboard}
          >
            Continue
          </button>
        </div>
      </main>
    )
  }

  return (
    <main className='w-screen h-screen mt-2 flex items-center justify-center'>
      <div className='w-[90%] max-w-2xl mx-auto flex flex-col gap-4 items-center justify-center'>
        <div className='w-40'>
          <Lottie animationData={errorLottie} loop={false} />
        </div>
        <h4 className='text-lg sm:text-3xl'>Payment Failed</h4>
        <p className='text-lg text-center'>
          Unfortunately, your payment couldn't be processed. Please wait a
          moment and try again. If the issue persists, contact support for
          assistance.
        </p>

        <button
          className='mt-5 tracking-wide font-semibold bg-blue-900 text-gray-100 py-4 px-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none'
          onClick={proceedToDashboard}
        >
          Back To Dashboard
        </button>
      </div>
    </main>
  )
}

export default SubscriptionSuccess
