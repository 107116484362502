import React, { useState, useEffect  } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link} from 'react-router-dom'
import axios from 'axios';



const ResetPassword = () => {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false)
    const [scrolled, setScrolled] = useState(false)
    const toggleMenu = () => {
    setIsOpen(!isOpen)
  }
    const API_BASE_URL = process.env.REACT_APP_API_URL;

    
    useEffect(() => {
        const handleScroll = () => {
          setScrolled(window.scrollY > 50)
        }
    
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
      }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newPassword) {
            setErrorMessage('Please enter a new password.');
            return;
        }
        try {
            setIsLoading(true);
            await axios.post(`${API_BASE_URL}/api/auth/reset-password/${token}`, {
                newPassword
            });
            setSuccessMessage('Password has been reset successfully!');
            setErrorMessage('');
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (error) {
            setErrorMessage(
                error.response?.data?.message || 'Failed to reset password. Please try again.'
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-gray-100 min-h-screen">
        {/* Navigation Menu */}
        <div className={`fixed top-0 left-0 w-full z-50 py-0 transition-colors duration-300 ${scrolled ? 'bg-[#2563EB]' : 'bg-transparent'} backdrop-blur`}>
          <div className="max-w-7xl mx-auto flex items-center justify-between px-4 py-4">
            {/* Logo */}
            <Link to="/">
              <img src="/assets/images/footer_logo.png" alt="Reekap Logo" className="w-24 md:w-32" />
            </Link>
  
            {/* Hamburger Icon */}
            <div className="md:hidden z-50">
              <button
                onClick={toggleMenu}
                className={`${scrolled ? 'text-white' : 'text-[#2563EB]'} focus:outline-none`}
                aria-label="Toggle menu"
              >
                {isOpen ? (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                  </svg>
                )}
              </button>
            </div>
  
            {/* Menu Links */}
            <div className={`${
                isOpen ? 'flex' : 'hidden'
              } md:flex flex-col md:flex-row items-center md:items-center absolute md:relative top-16 md:top-0 left-0 w-full md:w-auto bg-[#2c3e50] md:bg-transparent z-40 py-6 md:py-0 md:space-x-8`}
            >
              <Link
                to="/pricing"
                className={`${
                  scrolled ? 'text-white' : 'text-[#2563EB]'
                } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
              >
                Pricing
              </Link>
              <Link
                to="/faqs"
                className={`${
                  scrolled ? 'text-white' : 'text-[#2563EB]'
                } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
              >
                FAQs
              </Link>
              <Link
                to="/login"
                className={`${
                  scrolled ? 'text-white' : 'text-[#2563EB]'
                } font-medium text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
              >
                Login
              </Link>
              <Link
                to="/register"
                className={`${
                  scrolled ? 'bg-white text-[#2563EB]' : 'bg-[#2563EB] text-white'
                } py-2 px-4 rounded-lg font-semibold text-base md:text-lg hover:bg-white hover:text-[#2563EB] transition-colors duration-300 md:ml-4`}
              >
                Start for free
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-gray-100 min-h-screen flex items-center justify-center px-5 lg:px-0 mt-16">
            <div className="max-w-screen-md bg-white border shadow sm:rounded-lg p-8">
                <h1 className="text-2xl font-bold text-center text-blue-900 mb-6">Reset Password</h1>
                {successMessage && (
                    <div className="bg-green-100 text-green-700 p-4 rounded mb-4">
                        {successMessage}
                    </div>
                )}
                {errorMessage && (
                    <div className="bg-red-100 text-red-700 p-4 rounded mb-4">
                        {errorMessage}
                    </div>
                )}
                <form onSubmit={handleSubmit} className="space-y-6">
                    <input
                        type="password"
                        placeholder="Enter your new password"
                        className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <button
                        type="submit"
                        className="w-full py-3 bg-blue-900 text-white rounded-lg hover:bg-blue-800 transition-all duration-300"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Resetting...' : 'Reset Password'}
                    </button>
                </form>
            </div>
        </div>
        </div>
    );
};

export default ResetPassword;
