// Ensure all necessary imports are here
import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import LandingPage from './pages/LandingPage'
import DashboardPage from './pages/DashboardPage'
import UploadAudio from './pages/UploadAudio'
import PlanSelection from './pages/PlanSelection'
import Billing from './pages/Billing'
import CustomPlanRequest from './pages/CustomPlanRequest'
import ProfilePage from './pages/ProfilePage'
import Help from './pages/Help'
import TermsAndConditions from './pages/TermsAndConditions'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Login from './pages/Login'
import Register from './pages/Register'
import VerifyEmail from './pages/VerifyEmail'
import VerifyPhone from './pages/VerifyPhone'
import ProtectedRoute from './components/ProtectedRoute'
import useAuth from './hooks/useAuth'
import GoogleCallback from './pages/GoogleCallback'
import RecordingPage from './pages/RecordingPage'
import AdminLoginPage from './admin/AdminLogin'
import AdminDashboardPage from './admin/AdminDashboardPage'
import PlansManagementPage from './admin/PlansManagementPage'
import UsersManagementPage from './admin/UsersManagementPage'
import SubscriptionSuccess from './pages/SubscriptionSuccess'
import SubscriptionCancel from './pages/SubscriptionCancel'
import DownloadWindows from './pages/DownloadWindows';
import DownloadMac from './pages/DownloadMac';
import PricingPage from './pages/PricingPage';
import FaqPage from './pages/FaqPage';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './pages/ResetPasswordPage';
import WatchDemoPage from './pages/WatchDemoPage';


function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const { login } = useAuth()
 // Add this state for the install prompt
 const [installPrompt, setInstallPrompt] = useState(null);
 const [isInstallable, setIsInstallable] = useState(false);


 // Detect if the app is running in standalone mode and redirect to the login page
 useEffect(() => {
   const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

   if (isStandalone && location.pathname === '/') {
     // Redirect to the login page if the app is running as a PWA and the current path is the landing page
     navigate('/login');
   }
 }, [location.pathname, navigate]);


// Add this new useEffect for handling the install prompt
useEffect(() => {
  const handleBeforeInstallPrompt = (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later
    setInstallPrompt(e);
    // Show the install button/prompt to user
    setIsInstallable(true);
  };

  window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

  // Check if app is already installed
  window.addEventListener('appinstalled', () => {
    // Hide the install prompt once installed
    setIsInstallable(false);
    setInstallPrompt(null);
    console.log('PWA was installed');
  });

  return () => {
    window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  };
}, []);

// Add this function to handle install
const handleInstallClick = async () => {
  if (!installPrompt) return;

  // Show the install prompt
  const result = await installPrompt.prompt();
  console.log(`Install prompt was: ${result.outcome}`);
  
  // Reset the deferred prompt variable
  setInstallPrompt(null);
  setIsInstallable(false);
};


  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const token = params.get('authToken')

    if (token) {
      const userData = { token }
      login(userData)
      navigate('/dashboard')
    }
  }, [location.search, login, navigate])

 


  return (
    <>
      <div className="flex flex-col min-h-screen">

        {/* Add install button - you can style and position this as needed */}
        {isInstallable && (
          <button
            onClick={handleInstallClick}
            className="fixed bottom-16 left-1/2 transform -translate-x-1/2 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-600 transition-colors z-50"
          >
            Install App
          </button>
        )}
    
      <main className="flex-grow">
          <Routes>
            <Route path='/' element={<LandingPage />} />
            
            <Route
              path='/upload-audio'
              element={
                <ProtectedRoute>
                  <UploadAudio />
                </ProtectedRoute>
              }
            />
            <Route
              path='/plans'
              element={
                <ProtectedRoute>
                  <PlanSelection />
                </ProtectedRoute>
              }
            />
            <Route
              path='/billing'
              element={
                <ProtectedRoute>
                  <Billing />
                </ProtectedRoute>
              }
            />
            <Route
              path='/subscription/success'
              element={
                <ProtectedRoute>
                  <SubscriptionSuccess />
                </ProtectedRoute>
              }
            />
            <Route
              path='/subscription/cancel'
              element={
                <ProtectedRoute>
                  <SubscriptionCancel />
                </ProtectedRoute>
              }
            />
            <Route
              path='/custom-plan-request'
              element={
                <ProtectedRoute>
                  <CustomPlanRequest />
                </ProtectedRoute>
              }
            />
            <Route
              path='/profile'
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
             <Route
              path='/recording'
              element={
                <ProtectedRoute>
                  <RecordingPage />
                </ProtectedRoute>
              }
            />
            
            <Route
              path='/dashboard'
              element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
            
            
            
            


            <Route path='/help' element={<Help />} />
            <Route path='/terms' element={<TermsAndConditions />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/google-callback' element={<GoogleCallback />} />
            <Route path='/verify-email' element={<VerifyEmail />} />
            <Route path='/verify-phone' element={<VerifyPhone />} />
            <Route path='/admin-login' element={<AdminLoginPage />} />
            <Route path='/admin-dashboard' element={<AdminDashboardPage />} />
            <Route path='/pricing' element={<PricingPage />} /> 
            <Route path="/download-windows" element={<DownloadWindows />} />
            <Route path="/download-mac" element={<DownloadMac />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/watch-demo" element={<WatchDemoPage />} />
            <Route path="/faqs" element={<FaqPage />} /> 
           

            <Route
              path='/admin-manage-plans'
              element={<PlansManagementPage />}
            />
            <Route
              path='/admin-manage-users'
              element={<UsersManagementPage />}
            />
          
          </Routes>
        </main>
       

      
  
      </div>
    </>
  )
}

export default App
