import React from 'react'
import { convertSecondsToMinutes } from '../utils/secondsToMinutes'
import useAuth from '../hooks/useAuth'

const PlanCard = ({ plan, onSelect }) => {
  const { user } = useAuth()

  const userPlanId = user.subscription.planId

  const isUsersPlan = userPlanId === plan._id
  const isFreePlan = plan.name === 'Free' || plan?.price === 0

  const proceedToPayment = () => {
    if (isUsersPlan || isFreePlan) return
    onSelect(plan)
  }

  return (
    <div className='plan-card p-4 border rounded'>
      <h2 className='text-xl font-bold mb-2'>{plan.name}</h2>
      <p>{plan.description}</p>
      <p>Recording Minutes: {convertSecondsToMinutes(plan.allowedLimit)}</p>
      <p>Price: ${plan.price}</p>
      <button
        onClick={proceedToPayment}
        className={`${
          isUsersPlan
            ? 'bg-green-500'
            : isFreePlan
            ? 'bg-red-500'
            : 'bg-blue-500 hover:bg-blue-700'
        } text-white font-bold py-2 px-4 rounded mt-4 disabled:cursor-not-allowed`}
        disabled={isFreePlan}
      >
        {isUsersPlan ? 'Current Plan' : isFreePlan ? 'Free' : 'Select'}
      </button>
    </div>
  )
}

export default PlanCard
