// src/components/Header.js
import React from 'react';
import { FaBars, FaBell } from 'react-icons/fa';
import { Link} from 'react-router-dom';
import ProfileIconWithBg from './ProfileIconWithBg';

const Header = ({
  sidebarOpen,
  toggleSidebar,
  userName,
  openNotification,
  navigate, // Use this prop instead of creating a new navigate instance
}) => {
  const handleNavigateToRecording = () => {
    navigate('/recording');
  };
  
  const handleNavigateToUpload = () => {
    navigate('/upload-audio');
  };

  return (
    <header className="bg-white shadow-md p-4">
      <div className="flex flex-row justify-between items-center space-x-4">
        {/* Left Section: Hamburger Button and Search Bar */}
        <div className="flex items-center space-x-4 w-full md:w-auto">
          <button onClick={toggleSidebar} className="md:hidden p-2">
            <FaBars size={24} className="text-gray-700" />
          </button>

          {/* Search Bar (Visible on Desktop Only) */}
          <div className="hidden md:flex items-center w-full md:max-w-md">
            <div className="relative w-full">
              <input
                type="text"
                placeholder="Search"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#2563EB] placeholder:text-[rgba(23,23,23,0.30)]"
              />
              <span className="absolute right-3 top-2.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M14 14L11.1047 11.1047M11.1047 11.1047C11.5999 10.6094 11.9928 10.0215 12.2608 9.37438C12.5289 8.72729 12.6668 8.03375 12.6668 7.33335C12.6668 6.63295 12.5289 5.9394 12.2608 5.29232C11.9928 4.64523 11.5999 4.05727 11.1047 3.56202C10.6094 3.06676 10.0215 2.6739 9.37438 2.40586C8.72729 2.13783 8.03375 1.99988 7.33335 1.99988C6.63295 1.99988 5.9394 2.13783 5.29232 2.40586C4.64523 2.6739 4.05727 3.06676 3.56202 3.56202C2.5618 4.56224 1.99988 5.91882 1.99988 7.33335C1.99988 8.74787 2.5618 10.1045 3.56202 11.1047C4.56224 12.1049 5.91882 12.6668 7.33335 12.6668C8.74787 12.6668 10.1045 12.1049 11.1047 11.1047Z"
                    stroke="#171717"
                    strokeOpacity="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>

        {/* Right Section: Action Buttons, Notification, and Profile Icon */}
        <div className="flex items-center space-x-2">
          <button
            className="px-2 md:px-4 py-2 bg-[#2563EB] text-white rounded-lg hover:bg-[#1E3A8A] transition-colors duration-200 flex items-center justify-center"
            onClick={() => {
              handleNavigateToRecording();
            }}
          >
            <span className="mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M16.015 12.795C16.015 13.7272 15.6447 14.6213 14.9855 15.2805C14.3263 15.9397 13.4322 16.31 12.5 16.31C11.5678 16.31 10.6737 15.9397 10.0145 15.2805C9.35533 14.6213 8.985 13.7272 8.985 12.795V5.765C8.985 4.83276 9.35533 3.93871 10.0145 3.27952C10.6737 2.62033 11.5678 2.25 12.5 2.25C13.4322 2.25 14.3263 2.62033 14.9855 3.27952C15.6447 3.93871 16.015 4.83276 16.015 5.765V12.795Z"
                  stroke="#F3F4F6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.4595 12.795C18.4595 16.086 15.7915 18.7545 12.5 18.7545M12.5 18.7545C9.2085 18.7545 6.5405 16.086 6.5405 12.7945M12.5 18.7545V21.75"
                  stroke="#F3F4F6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            Record
          </button>
          <button
            className="px-2 md:px-4 py-2 border border-[#2563EB] text-[#2563EB] rounded-lg hover:bg-[#2563EB] hover:text-white transition-colors duration-200 flex items-center justify-center"
            onClick={() => {
              handleNavigateToUpload();
            }}
          >
            <span className="mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.5 15.577V6.927L9.17 9.257L8.462 8.539L12 5L15.539 8.539L14.831 9.258L12.5 6.927V15.577H11.5ZM6.616 19C6.15533 19 5.771 18.846 5.463 18.538C5.155 18.23 5.00067 17.8453 5 17.384V14.961H6V17.384C6 17.538 6.064 17.6793 6.192 17.808C6.32 17.9367 6.461 18.0007 6.615 18H17.385C17.5383 18 17.6793 17.936 17.808 17.808C17.9367 17.68 18.0007 17.5387 18 17.384V14.961H19V17.384C19 17.8447 18.846 18.229 18.538 18.537C18.23 18.845 17.8453 18.9993 17.384 19H6.616Z"
                  fill="#2563EB"
                />
              </svg>
            </span>
            Upload
          </button>
          <button
            className="relative text-gray-700 hover:text-[#2563EB]"
            onClick={openNotification}
          >
            <FaBell size={24} />
            <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
              1
            </span>
          </button>
          <Link to="/profile" className="text-white hover:text-[#38b6ff]">
            <ProfileIconWithBg name={userName} />
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
