import React from 'react';
import PropTypes from 'prop-types';
import getInitials from '../utils/getInitials';

const ProfileIconWithBg = ({ name }) => {
  const initials = getInitials(name);

  return (
    <div className="flex items-center justify-center bg-blue-500 text-white font-bold rounded-full w-10 h-10">
      {initials}
    </div>
  );
};

ProfileIconWithBg.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ProfileIconWithBg;
