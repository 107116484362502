import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import useAuth from '../hooks/useAuth'
import { Helmet } from 'react-helmet';

const Register = () => {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const [consentGiven, setConsentGiven] = useState(false)

  const { setUser } = useAuth()
  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const API_BASE_URL = process.env.REACT_APP_API_URL

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    setMessage('')

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{6,}$/

    if (!emailPattern.test(email)) {
      setError('Please enter a valid email address.')
      return
    }

    if (!passwordPattern.test(password)) {
      setError(
        'Password must be at least 6 characters long, contain a capital letter, a symbol, and a number.'
      )
      return
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.')
      return
    }

    try {
      setIsLoading(true)
      const response = await axios.post(`${API_BASE_URL}/api/auth/register`, {
        username,
        email,
        password,
      })
      setUser({ ...response.data.data })
      setMessage(response.data.message)
      navigate('/verify-email')
    } catch (err) {
      setError(err.response?.data?.message || 'Registration failed.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleGoogleSignUp = () => {
    window.location.href = `${API_BASE_URL}/api/auth/google`
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleConsentChange = (e) => {
    setConsentGiven(e.target.checked)
  }

  return (
    <div className='bg-gray-100 min-h-screen'>

<Helmet>
        <title>Reekap - Create Your Account</title>
        <meta
          name="description"
          content="Sign up for Reekap to access powerful meeting transcriptions, summaries, and more. Get started for free with a secure and easy registration process."
        />
        <meta
          name="keywords"
          content="Reekap sign up, meeting transcription sign up, secure registration, Reekap account"
        />
        <meta property="og:title" content="Reekap - Create Your Account" />
        <meta property="og:description" content="Join Reekap today to turn your meetings into smart summaries. Create an account for free and start capturing your meetings securely." />
        <meta property="og:image" content="https://reekap.com/assets/images/herobgimage.png" />
        <meta property="og:url" content="https://www.reekap.com/register" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {/* Navigation Menu */}
      <div
        className={`fixed top-0 left-0 w-full z-50 py-0 transition-colors duration-300 ${
          scrolled ? 'bg-[#2563EB]' : 'bg-transparent'
        } backdrop-blur`}
      >
        <div className='max-w-7xl mx-auto flex items-center justify-between px-4 py-4'>
          {/* Logo */}
          <Link to='/'>
            <img
              src='/assets/images/footer_logo.png'
              alt='Reekap Logo'
              className='w-24 md:w-32'
            />
          </Link>

          {/* Hamburger Icon */}
          <div className='md:hidden z-50'>
            <button
              onClick={toggleMenu}
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } focus:outline-none`}
              aria-label='Toggle menu'
            >
              {isOpen ? (
                <svg
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              ) : (
                <svg
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 6h16M4 12h16m-7 6h7'
                  />
                </svg>
              )}
            </button>
          </div>

          {/* Menu Links */}
          <div
            className={`${
              isOpen ? 'flex' : 'hidden'
            } md:flex flex-col md:flex-row items-center md:items-center absolute md:relative top-16 md:top-0 left-0 w-full md:w-auto bg-[#2c3e50] md:bg-transparent z-40 py-6 md:py-0 md:space-x-8`}
          >
            <Link
              to='/pricing'
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
            >
              Pricing
            </Link>
            <Link
              to='/faqs'
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
            >
              FAQs
            </Link>
            <Link
              to='/login'
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-medium text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
            >
              Login
            </Link>
            <Link
              to='/register'
              className={`${
                scrolled ? 'bg-white text-[#2563EB]' : 'bg-[#2563EB] text-white'
              } py-2 px-4 rounded-lg font-semibold text-base md:text-lg hover:bg-white hover:text-[#2563EB] transition-colors duration-300 md:ml-4`}
            >
              Start for free
            </Link>
          </div>
        </div>
      </div>

      <div className='h-screen flex items-center justify-center px-5 lg:px-0 mt-28'>
        <div className='max-w-screen-xl bg-white border shadow sm:rounded-lg flex justify-center flex-1'>
          <div className='flex-1 bg-blue-900 text-center hidden md:flex'>
            <div
              className='m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat'
              style={{
                backgroundImage: `url(https://www.tailwindtap.com/assets/common/marketing.svg)`,
              }}
            ></div>
          </div>
          <div className='lg:w-1/2 xl:w-5/12 p-6 sm:p-12'>
            <div className='flex flex-col items-center'>
              <div className='text-center'>
                <h1 className='text-2xl xl:text-4xl font-extrabold text-blue-900 mb-2'>
                  Sign Up
                </h1>
                <p className='text-sm text-gray-500 mb-6'>
                  Enter your details below to create your account.
                </p>
              </div>
              <form className='w-full flex-1 mt-8' onSubmit={handleSubmit}>
                <div className='mx-auto max-w-xs flex flex-col gap-4'>
                  <input
                    className='w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white'
                    type='text'
                    placeholder='Enter your name'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  <input
                    className='w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white'
                    type='email'
                    placeholder='Enter your email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <input
                    className='w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white'
                    type='password'
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <input
                    className='w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white'
                    type='password'
                    placeholder='Confirm Password'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />

                  {/* Consent Checkbox */}
                  <div className='flex items-start'>
                    <input
                      type='checkbox'
                      id='consent'
                      checked={consentGiven}
                      onChange={handleConsentChange}
                      className='mt-1 mr-2'
                    />
                    <label htmlFor='consent' className='text-sm text-gray-600'>
                      I acknowledge that Reekap does not store any recordings or
                      data. I am responsible for the storage and management of
                      my organization's data.
                      <Link to='/terms' className='text-blue-900 font-semibold'>
                        {' '}
                        Read terms
                      </Link>
                      .
                    </label>
                  </div>

                  <button
                    className={`mt-5 tracking-wide font-semibold w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ${
                      consentGiven
                        ? 'bg-blue-900 text-gray-100 hover:bg-indigo-700'
                        : 'bg-gray-400 text-gray-300'
                    }`}
                    disabled={!consentGiven || isLoading}
                  >
                    {isLoading ? (
                      <div className='loading'></div>
                    ) : (
                      <>
                        {' '}
                        <svg
                          className='w-6 h-6 -ml-2'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        >
                          <path d='M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2' />
                          <circle cx='8.5' cy='7' r='4' />
                          <path d='M20 8v6M23 11h-6' />
                        </svg>
                        <span className='ml-3'>Sign Up</span>
                      </>
                    )}
                  </button>
                  <button
                    type='button'
                    className={`mt-3 tracking-wide font-semibold w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ${
                      consentGiven
                        ? 'bg-red-600 text-gray-100 hover:bg-red-700'
                        : 'bg-gray-400 text-gray-300'
                    }`}
                    onClick={handleGoogleSignUp}
                    disabled={!consentGiven}
                  >
                    <svg
                      className='w-6 h-6 -ml-2'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <path d='M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c13 8 27 0 27-16a8.9 8.9 0 0 0-.08-1.32A7.72 7.72 0 0 0 23 3z' />
                    </svg>
                    <span className='ml-3'>Sign Up with Google</span>
                  </button>
                  {error && (
                    <p className='text-red-500 text-sm text-center mt-4'>
                      {error}
                    </p>
                  )}
                  {message && (
                    <p className='text-green-500 text-sm text-center mt-4'>
                      {message}
                    </p>
                  )}
                  <p className='mt-6 mb-3 text-xs text-gray-600 text-center'>
                    Already have an account?{' '}
                    <Link to='/login'>
                      <span className='text-blue-900 font-semibold'>
                        Sign in
                      </span>
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
