// src/components/FAQ.js

import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const FAQ = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const faqData = [
    {
      question: 'Can others tell I’m recording?',
      answer: 'No. Reekap works discreetly in the background, allowing you to focus on the conversation while capturing every detail.',
    },
    {
      question: 'How do I capture everyone’s voice in the meeting?',
      answer: 'For best results, use Reekap on a separate device from your meeting device. For example, if you’re in a Zoom meeting on your laptop, run Reekap on your phone to capture all voices clearly. We’re developing a browser extension for single-device recording.',
    },
    {
      question: 'Is my meeting data secure?',
      answer: 'Absolutely. We never store your meeting recordings, transcripts, or summaries on our servers. Everything stays locally on your device, ensuring complete privacy and compliance with company security policies.',
    },
    {
      question: 'Can I cancel my plan at any time?',
      answer: 'Yes, you can cancel your subscription at any time from your account settings. You will still have access to your plan’s features until the end of the billing cycle.',
    },
    {
      question: 'How do I top up my minutes?',
      answer: 'For paid plans, we offer a top-up option. You can purchase additional minutes from the account settings page.',
    },
    {
      question: 'How do I get the best audio quality via the web app?',
      answer: 'To achieve the best audio quality, use a good quality microphone and ensure a stable internet connection. Adjusting your settings in the app can also enhance the audio quality.',
    },
  ];

  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  return (
    <div className="bg-[#F3F4F6] py-20 px-4">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-[#2C3E50] font-outfit text-3xl md:text-[39px] font-bold text-center mb-6">
          Frequently Asked Questions
        </h2>
        <p className="text-[rgba(23,23,23,0.60)] text-base md:text-lg text-center mb-12 max-w-2xl mx-auto">
          Quick Answers to Help You Get Started
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {faqData.map((item, index) => (
            <div key={index} className="bg-white rounded-lg shadow-sm p-6">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleQuestion(index)}
              >
                <p className="text-[#171717] text-lg font-medium">
                  {item.question}
                </p>
                {activeQuestion === index ? (
                  <FaMinus className="text-[#2C3E50] w-6 h-6" />
                ) : (
                  <FaPlus className="text-[#2C3E50] w-6 h-6" />
                )}
              </div>
              {activeQuestion === index && (
                <p className="text-[rgba(23,23,23,0.60)] mt-4">
                  {item.answer}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
