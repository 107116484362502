import React from 'react';
import { useNavigate } from 'react-router-dom';

const recordIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
    <path d="M26.3583 21.7917C26.3583 23.3454 25.7411 24.8355 24.6425 25.9342C23.5438 27.0328 22.0537 27.65 20.5 27.65C18.9463 27.65 17.4562 27.0328 16.3575 25.9342C15.2589 24.8355 14.6417 23.3454 14.6417 21.7917V10.075C14.6417 8.52131 15.2589 7.03122 16.3575 5.93257C17.4562 4.83392 18.9463 4.21671 20.5 4.21671C22.0537 4.21671 23.5438 4.83392 24.6425 5.93257C25.7411 7.03122 26.3583 8.52131 26.3583 10.075V21.7917Z" stroke="#15803D" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M30.4325 21.7917C30.4325 27.2767 25.9858 31.7242 20.5 31.7242M20.5 31.7242C15.0142 31.7242 10.5675 27.2767 10.5675 21.7909M20.5 31.7242V36.7167" stroke="#15803D" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const uploadIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
    <path d="M19.1667 26.5432V11.8488L15.2833 15.8069L14.1033 14.5872L20 8.57523L25.8983 14.5872L24.7183 15.8086L20.8333 11.8488V26.5432H19.1667ZM11.0267 32.3582C10.2589 32.3582 9.61833 32.0966 9.105 31.5733C8.59167 31.0501 8.33444 30.3966 8.33333 29.6129V25.4968H10V29.6129C10 29.8746 10.1067 30.1146 10.32 30.3332C10.5333 30.5518 10.7683 30.6605 11.025 30.6594H28.975C29.2306 30.6594 29.4656 30.5507 29.68 30.3332C29.8944 30.1158 30.0011 29.8757 30 29.6129V25.4968H31.6667V29.6129C31.6667 30.3955 31.41 31.0484 30.8967 31.5716C30.3833 32.0949 29.7422 32.357 28.9733 32.3582H11.0267Z" fill="#15803D"/>
  </svg>
);

const QuickActions = () => {
  const navigate = useNavigate();

  const handleNavigateToRecording = () => {
    navigate('/recording');
  };

  const handleNavigateToUpload = () => {
    navigate('/upload-audio');
  };

  return (
    <section className="mb-8">
      <h2 className="text-xl sm:text-2xl font-semibold mb-4">Quick Actions</h2>
      <div className="flex flex-col lg:flex-row gap-4">
        <button
          onClick={handleNavigateToRecording}
          className="flex-1 bg-[rgba(21,128,61,0.20)] text-[#15803D] py-6 px-6 rounded-[4px] shadow-sm flex items-center justify-center w-full lg:w-auto transition-transform duration-300 transform hover:scale-105 hover:bg-[rgba(21,128,61,0.30)] hover:shadow-md"
        >
          {recordIcon}
          <span className="ml-2 text-[20px] font-normal">Record Meeting</span>
        </button>
        <button
          onClick={handleNavigateToUpload}
          className="flex-1 bg-[rgba(21,128,61,0.20)] text-[#15803D] py-6 px-6 rounded-[4px] shadow-sm flex items-center justify-center w-full lg:w-auto transition-transform duration-300 transform hover:scale-105 hover:bg-[rgba(21,128,61,0.30)] hover:shadow-md"
        >
          {uploadIcon}
          <span className="ml-2 text-[20px] font-normal">Upload Recording</span>
        </button>
      </div>
    </section>
  );
};

export default QuickActions;
