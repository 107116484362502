import React, { useState, useEffect } from 'react'
import axios from 'axios'

const PlanManagementPage = () => {
  const [plans, setPlans] = useState([])
  const [showAddForm, setShowAddForm] = useState(false)
  const [showEditForm, setShowEditForm] = useState(null)
  const [newPlan, setNewPlan] = useState({
    name: '',
    description: '',
    allowedLimit: '',
    price: '',
  })
  const [editedPlan, setEditedPlan] = useState({
    name: '',
    description: '',
    allowedLimit: '',
    price: '',
  })

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/plans`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
            },
          }
        )
        setPlans(response.data.data)
      } catch (error) {
        console.error('Error fetching plans:', error)
      }
    }

    fetchPlans()
  }, [])

  const handleAddPlan = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/plans`,
        newPlan,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )
      setNewPlan({ name: '', description: '', allowedLimit: '', price: '' })
      setShowAddForm(false)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/plans`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )
      setPlans(response.data.data)
    } catch (error) {
      console.error('Error adding plan:', error)
    }
  }

  const handleEditPlan = async (id) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/admin/plans/${id}`,
        editedPlan,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )
      setEditedPlan({ name: '', description: '', allowedLimit: '', price: '' })
      setShowEditForm(null)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/plans`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )
      setPlans(response.data.data)
    } catch (error) {
      console.error('Error updating plan:', error)
    }
  }

  const handleDeletePlan = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/admin/plans/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/plans`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )
      setPlans(response.data.data)
    } catch (error) {
      console.error('Error deleting plan:', error)
    }
  }

  return (
    <div className='p-6'>
      <div className='flex justify-between items-center mb-6'>
        <h1 className='text-2xl font-bold'>Plan Management</h1>
        <button
          onClick={() => setShowAddForm(!showAddForm)}
          className='bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600'
        >
          {showAddForm ? 'Cancel' : 'Add New Plan'}
        </button>
      </div>

      {showAddForm && (
        <div className='mb-6 p-4 bg-white border rounded shadow-md'>
          <h2 className='text-xl font-semibold mb-4'>Add New Plan</h2>
          <input
            type='text'
            placeholder='Name'
            value={newPlan.name}
            onChange={(e) => setNewPlan({ ...newPlan, name: e.target.value })}
            className='mb-4 w-full p-2 border rounded'
          />
          <input
            type='text'
            placeholder='Description'
            value={newPlan.description}
            onChange={(e) =>
              setNewPlan({ ...newPlan, description: e.target.value })
            }
            className='mb-4 w-full p-2 border rounded'
          />
          <input
            type='number'
            placeholder='Allowed Limit'
            value={newPlan.allowedLimit}
            onChange={(e) =>
              setNewPlan({ ...newPlan, allowedLimit: e.target.value })
            }
            className='mb-4 w-full p-2 border rounded'
          />
          <input
            type='number'
            placeholder='Price'
            value={newPlan.price}
            onChange={(e) => setNewPlan({ ...newPlan, price: e.target.value })}
            className='mb-4 w-full p-2 border rounded'
          />
          <button
            onClick={handleAddPlan}
            className='bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600'
          >
            Add Plan
          </button>
        </div>
      )}

      <div className='grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'>
        {plans.length > 0 ? (
          plans.map((plan) => (
            <div key={plan._id} className='bg-white p-4 rounded-lg shadow-lg'>
              <h2 className='text-xl font-semibold mb-2'>{plan.name}</h2>
              <p className='text-gray-700 mb-2'>{plan.description}</p>
              <p className='text-gray-600 mb-2'>
                Allowed Limit: {plan.allowedLimit}
              </p>
              <p className='text-gray-600 mb-2'>Price: ${plan.price}</p>
              <div className='flex gap-4 mt-4'>
                <button
                  onClick={() => {
                    setEditedPlan({
                      name: plan.name,
                      description: plan.description,
                      allowedLimit: plan.allowedLimit,
                      price: plan.price,
                    })
                    setShowEditForm(plan._id)
                  }}
                  className='bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600'
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeletePlan(plan._id)}
                  className='bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600'
                >
                  Delete
                </button>
              </div>
              {showEditForm === plan._id && (
                <div className='mt-4 p-4 bg-gray-100 border rounded'>
                  <h3 className='text-lg font-semibold mb-4'>Edit Plan</h3>
                  <input
                    type='text'
                    placeholder='Name'
                    value={editedPlan.name}
                    onChange={(e) =>
                      setEditedPlan({ ...editedPlan, name: e.target.value })
                    }
                    className='mb-4 w-full p-2 border rounded'
                  />
                  <input
                    type='text'
                    placeholder='Description'
                    value={editedPlan.description}
                    onChange={(e) =>
                      setEditedPlan({
                        ...editedPlan,
                        description: e.target.value,
                      })
                    }
                    className='mb-4 w-full p-2 border rounded'
                  />
                  <input
                    type='number'
                    placeholder='Allowed Limit'
                    value={editedPlan.allowedLimit}
                    onChange={(e) =>
                      setEditedPlan({
                        ...editedPlan,
                        allowedLimit: e.target.value,
                      })
                    }
                    className='mb-4 w-full p-2 border rounded'
                  />
                  <input
                    type='number'
                    placeholder='Price'
                    value={editedPlan.price}
                    onChange={(e) =>
                      setEditedPlan({ ...editedPlan, price: e.target.value })
                    }
                    className='mb-4 w-full p-2 border rounded'
                  />
                  <button
                    onClick={() => handleEditPlan(plan._id)}
                    className='bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600'
                  >
                    Save Changes
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No plans available.</p>
        )}
      </div>
    </div>
  )
}

export default PlanManagementPage
