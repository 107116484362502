import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const WatchDemoPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className="bg-cover bg-center min-h-screen"
      style={{ backgroundImage: 'url(/assets/images/pricing_page_bg.png)' }}
    >
      <Helmet>
        <title>Reekap Demo - How to use Reekap</title>
        <meta
          name="description"
          content="Watch how to use Reekap's meeting transcription, meeting minutes, and detailed summary generation. Learn how Reekap can improve your meeting productivity."
        />
        <meta
          name="keywords"
          content="Reekap Demo Video, meeting transcription help, Reekap support, data privacy"
        />
        <meta property="og:title" content="Reekap Demo - How to use Reekap" />
        <meta property="og:description" content="Watch the demo of Reekap's features for AI-powered meeting transcription and summaries." />
        <meta property="og:image" content="https://reekap.com/assets/images/herobgimage.png" />
        <meta property="og:url" content="https://www.reekap.com/watch-demo" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {/* Navigation Menu */}
      <div className={`fixed top-0 left-0 w-full z-50 transition-colors duration-300 ${scrolled ? 'bg-[#2563EB]' : 'bg-transparent'} backdrop-blur`}>
        <div className="max-w-7xl mx-auto flex items-center justify-between px-4 py-4">
          <Link to="/">
            <img src="/assets/images/footer_logo.png" alt="Reekap Logo" className="w-24 md:w-32" />
          </Link>

          {/* Hamburger Icon */}
          <div className="md:hidden z-50">
            <button
              onClick={toggleMenu}
              className={`${scrolled ? 'text-white' : 'text-[#2563EB]'} focus:outline-none`}
              aria-label="Toggle menu"
            >
              {isOpen ? (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              )}
            </button>
          </div>

          {/* Menu Links */}
          <div
            className={`${
              isOpen ? 'flex' : 'hidden'
            } md:flex flex-col md:flex-row items-center md:items-center absolute md:relative top-16 md:top-0 left-0 w-full md:w-auto bg-[#2c3e50] md:bg-transparent z-40 py-6 md:py-0 md:space-x-8`}
          >
            <Link to="/pricing" className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}>
              Pricing
            </Link>
            <Link to="/faqs" className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}>
              FAQs
            </Link>
            <Link to="/login" className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-medium text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}>
              Login
            </Link>
            <Link to="/register" className={`${
                scrolled ? 'bg-white text-[#2563EB]' : 'bg-[#2563EB] text-white'
              } py-2 px-4 rounded-lg font-semibold text-base md:text-lg hover:bg-white hover:text-[#2563EB] transition-colors duration-300 md:ml-4`}>
              Start for free
            </Link>
          </div>
        </div>
      </div>

      {/* Hero Section Padding Adjustment */}
      <div className="pt-16">
      <div className="min-h-full md:min-h-screen pt-8 md:pt-16 pb-8 md:pb-16">
  <div className="max-w-4xl w-full mx-auto p-4">
    <h1 className="text-2xl md:text-3xl font-bold text-center mb-4 md:mb-6">Watch Demo</h1>
    <video controls className="w-full rounded-lg shadow-lg">
      <source src="/assets/videos/demo-video.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</div>


        {/* CTA Section */}
        <div className="bg-[#1E2D3C] py-20 px-4">
          <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center md:justify-between">
            <div className="md:w-1/2 text-center md:text-left mb-8 md:mb-0 md:mr-36">
              <h3 className="text-white font-outfit text-[20px] font-bold leading-[24px] mb-2">Transform How You Handle Meetings</h3>
              <h2 className="text-white font-outfit text-[28px] md:text-[39px] font-normal leading-[46.8px] mb-4">
                Join thousands of professionals who stopped worrying about missing important details.
              </h2>
              <p className="text-[rgba(243,244,246,0.60)] font-outfit text-[20px] font-normal leading-[24px] mb-8">
                Whether you're a new team member trying to catch up quickly, or a seasoned professional managing multiple meetings - Reekap helps you stay on top of everything while remaining fully present in conversations.
              </p>
              <Link to="/register">
                <button className="bg-[#2563EB] text-white rounded-[30px] py-4 px-6 md:px-8 shadow-md font-outfit text-[16px] leading-[19.2px] hover:bg-white hover:text-[#2563EB] transition-colors duration-300">
                  Start Today!
                </button>
              </Link>
            </div>
            <div className="md:w-1/2 flex justify-center md:justify-end">
              <img
                src="/assets/images/ctadashboardimage.png"
                alt="Dashboard Preview"
                className="max-w-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>

        {/* Footer Section */}
        <div className="bg-[#F3F4F6] py-10">
          <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center md:items-start justify-between px-4 space-y-6 md:space-y-0">
            <div className="w-full md:w-1/6 flex justify-center md:justify-start">
              <Link to="/">
                <img src="/assets/images/footer_logo.png" alt="Reekap Logo" className="w-[123px]" />
              </Link>
            </div>
            <div className="w-full md:w-2/3 flex flex-col items-center md:items-start md:ml-8">
              <div className="flex flex-wrap justify-center md:justify-start space-x-6 text-[#171717] font-outfit text-[16px] font-normal mb-4">
                <Link to="/pricing" className="hover:text-[#2563EB] transition-colors duration-300">Pricing</Link>
                <Link to="/faqs" className="hover:text-[#2563EB] transition-colors duration-300">FAQs</Link>
                <Link to="/login" className="hover:text-[#2563EB] transition-colors duration-300">Login</Link>
                <Link to="/register" className="hover:text-[#2563EB] transition-colors duration-300">Signup</Link>
              </div>
              <div className="w-full h-[1px] bg-[#171717] mb-4"></div>
              <div className="flex flex-wrap justify-center md:justify-start space-x-6 text-[#171717] font-outfit text-[16px] font-normal">
                <Link to="/privacy" className="hover:text-[#2563EB] transition-colors duration-300">Privacy Policy</Link>
                <Link to="/terms" className="hover:text-[#2563EB] transition-colors duration-300">Terms & Conditions</Link>
              </div>
            </div>
            <div className="w-full md:w-1/6 flex justify-center md:justify-end space-x-4">
              <a href="https://facebook.com" aria-label="Facebook">
                <FaFacebookF className="text-[#171717] w-5 h-5" />
              </a>
              <a href="https://instagram.com" aria-label="Instagram">
                <FaInstagram className="text-[#171717] w-5 h-5" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchDemoPage;
