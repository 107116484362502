import React from 'react';
import { Link } from 'react-router-dom';

const DownloadMac = () => {
  return (
    <div className="container mx-auto p-4 min-h-screen">
      <h1 className="text-4xl font-bold text-[#2c3e50] mb-8 text-center">
        Download Reekap for Mac
      </h1>
      <p className="text-lg text-gray-700 text-center mb-12">
        Follow these steps to download and install Reekap on your Mac device.
      </p>

      {/* Step-by-Step Guide Section */}
      <div className="bg-white rounded-lg shadow-md p-8 border border-[#38b6ff] mb-12">
        <h2 className="text-2xl font-semibold text-[#2c3e50] mb-4">Step-by-Step Installation Guide</h2>
        <ol className="list-decimal list-inside text-gray-600 space-y-4">
          <li>
            Click the button below to download the Reekap installer for Mac.
            <div className="text-center my-4">
              <a
                href="/"
                className="bg-[#38b6ff] text-white font-bold py-2 px-6 rounded hover:bg-blue-600 transition duration-200"
              >
                Download Installer
              </a>
            </div>
          </li>
          <li>
            Once the download is complete, locate the <strong>Reekap.dmg</strong> file in your Downloads folder and double-click it to start the installation.
          </li>
          <li>
            Drag the Reekap icon into the Applications folder.
          </li>
          <li>
            Launch Reekap from your Applications folder or Dock.
          </li>
          <li>
            Sign in to your Reekap account or create a new one if you haven't already.
          </li>
        </ol>
      </div>

      {/* Troubleshooting Section */}
      <div className="bg-gray-100 rounded-lg shadow-md p-8 border border-gray-300 mb-12">
        <h2 className="text-xl font-semibold text-[#2c3e50] mb-4">Troubleshooting Tips</h2>
        <p className="text-gray-600">
          If you encounter any issues during installation, try the following:
        </p>
        <ul className="list-disc list-inside text-gray-600 space-y-2 mt-4">
          <li>Ensure that you have permissions to install applications from unknown sources (System Preferences &gt; Security & Privacy).</li>
          <li>Restart your Mac and try reinstalling the application.</li>
          <li>Check that you have enough storage space available for the installation.</li>
        </ul>
        <p className="text-gray-600 mt-4">
          If the issue persists, feel free to <Link to="/help" className="text-[#38b6ff] underline">contact our support team</Link>.
        </p>
      </div>

      {/* CTA Section */}
      <div className="text-center">
        <Link to="/login" className="bg-[#38b6ff] text-white font-bold py-2 px-6 rounded hover:bg-blue-600 transition duration-200">
          Sign In to Reekap
        </Link>
      </div>
    </div>
  );
};

export default DownloadMac;
