import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { convertSecondsToMinutes } from '../utils/secondsToMinutes';

const recordIcon = '/assets/images/recordyoudb.png';
const uploadIcon = '/assets/images/uploadyoudb.png';
const totalUsageIcon = '/assets/images/totalusagedb.png';

const UsageStatistics = () => {
  const [usageStats, setUsageStats] = useState({
    recordingSeconds: 0,
    uploadSeconds: 0,
    maxAllowedLimit: 0,
  });

  useEffect(() => {
    const fetchUsageStats = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/usage`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
            },
          }
        );

        const data = response.data;
        const { recordingSeconds, uploadSeconds, allowedLimit } = data.data;

        setUsageStats({
          recordingSeconds: recordingSeconds || 0,
          uploadSeconds: uploadSeconds || 0,
          maxAllowedLimit: allowedLimit,
        });
      } catch (error) {
        console.error('Error fetching usage statistics:', error);
        setUsageStats({
          recordingSeconds: 0,
          uploadSeconds: 0,
          maxAllowedLimit: 0,
        });
      }
    };

    fetchUsageStats();
  }, []);

  // Adjust the values if they are off
  const adjustedAllowedLimit = usageStats.maxAllowedLimit / 60;
  const adjustedRecordingMinutes = convertSecondsToMinutes(
    usageStats.recordingSeconds
  );
  const adjustedUploadMinutes = convertSecondsToMinutes(
    usageStats.uploadSeconds
  );
  const totalUsageMinutes = adjustedRecordingMinutes + adjustedUploadMinutes;
  const remainingMinutes = Math.max(adjustedAllowedLimit - totalUsageMinutes, 0);

  const usageItems = [
    {
      icon: recordIcon,
      label: 'Minutes Recorded',
      value: adjustedRecordingMinutes,
    },
    {
      icon: uploadIcon,
      label: 'Minutes Uploaded',
      value: adjustedUploadMinutes,
    },
    {
      icon: totalUsageIcon,
      label: 'Total Usage',
      value: totalUsageMinutes,
    },
  ];

  return (
    <section className="mb-8 w-full">
      <h2 className="text-xl sm:text-2xl font-semibold mb-4">Usage Statistics</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
        {usageItems.map((item, index) => (
          <div
            key={index}
            className="bg-white rounded-[11.14px] p-4 flex flex-col shadow-sm"
          >
            <div className="flex items-center">
              <img src={item.icon} alt={item.label} className="h-[40px] w-[40px] sm:h-[60px] sm:w-[60px]" />
              <div className="ml-2 sm:ml-4">
                <h3 className="text-lg sm:text-[25px] text-[#171717] font-normal">
                  {item.label}
                </h3>
                <p className="text-lg sm:text-[25px] text-[#2C3E50] font-normal">
                  {item.value} Minutes
                </p>
              </div>
            </div>
            <p className="text-[#EF4444] text-xs sm:text-[12.746px] font-normal mt-2">
              {remainingMinutes} Mins Left
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default UsageStatistics;
