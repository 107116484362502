import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import useAuth from '../hooks/useAuth'
import { Helmet } from 'react-helmet';

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const { login, setUser } = useAuth()
  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const API_BASE_URL = process.env.REACT_APP_API_URL

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!email || !password) {
      setErrorMessage('Please fill in both fields.')
      return
    }

    try {
      setIsLoading(true)
      const response = await axios.post(`${API_BASE_URL}/api/auth/login`, {
        email,
        password,
      })

      // Store token and navigate
      localStorage.setItem('reekapToken', response.data.token)
      login(response.data)
      navigate('/dashboard')
    } catch (error) {
      const errorMsg = error.response?.data

      // Default to a friendly generic message for unknown errors
      let message = 'An error occurred. Please try again later.'

      // Handle specific error scenarios based on the response status or error codes
      if (error.response) {
        switch (error.response.status) {
          case 404:
            message =
              "No account found with this email address. Please check and try again or sign up if you don't have an account."
            break
          case 401:
            if (errorMsg?.error === 'Incorrect password') {
              message =
                'The password you entered is incorrect. Please try again.'
            } else if (errorMsg?.error === 'Please verify your email first.') {
              message =
                'Your email is not verified. Please verify your email before logging in.'
              setUser({ id: errorMsg.id, isEmailVerified: false })
              navigate('/verify-email')
            } else if (errorMsg?.error === 'Please verify your phone first.') {
              message =
                'Your phone number is not verified. Please verify your phone before logging in.'
              setUser({
                id: errorMsg.id,
                isEmailVerified: true,
                isPhoneVerified: false,
              })
              navigate('/verify-phone')
            } else {
              message =
                errorMsg?.error || 'Invalid credentials. Please try again.'
            }
            break
          case 400:
            if (errorMsg?.error === 'Google Authentication') {
              message =
                'This account is linked to Google Authentication. Please use "Sign in with Google" or set a new password using the forgot password link.'
            } else if (errorMsg?.error === 'Email already exists') {
              message =
                'An account with this email already exists. Please try logging in instead.'
            } else {
              message = errorMsg?.message || 'Bad request. Please try again.'
            }
            break
          case 429:
            message =
              'Too many login attempts. Please try again after some time.'
            break
          case 500:
            message = 'Something went wrong on our end. Please try again later.'
            break
          default:
            message =
              errorMsg?.error ||
              errorMsg?.message ||
              'An error occurred. Please try again.'
        }
      } else if (error.message === 'Network Error') {
        message =
          'Network error. Please check your internet connection and try again.'
      }

      setErrorMessage(message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleGoogleSignIn = () => {
    window.location.href = `${API_BASE_URL}/api/auth/google`
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className='bg-gray-100 min-h-screen'>

<Helmet>
        <title>Reekap - Login to Your Account</title>
        <meta
          name="description"
          content="Log in to your Reekap account to access your meeting transcriptions, summaries, and more. Secure login with email, password, or Google."
        />
        <meta
          name="keywords"
          content="Reekap login, meeting transcription login, secure login, Reekap account access"
        />
        <meta property="og:title" content="Reekap - Login to Your Account" />
        <meta property="og:description" content="Access your Reekap account for meeting transcriptions and smart summaries. Log in securely with your credentials." />
        <meta property="og:image" content="https://reekap.com/assets/images/herobgimage.png" />
        <meta property="og:url" content="https://www.reekap.com/login" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {/* Navigation Menu */}
      <div
        className={`fixed top-0 left-0 w-full z-50 py-0 transition-colors duration-300 ${
          scrolled ? 'bg-[#2563EB]' : 'bg-transparent'
        } backdrop-blur`}
      >
        <div className='max-w-7xl mx-auto flex items-center justify-between px-4 py-4'>
          {/* Logo */}
          <Link to='/'>
            <img
              src='/assets/images/footer_logo.png'
              alt='Reekap Logo'
              className='w-24 md:w-32'
            />
          </Link>

          {/* Hamburger Icon */}
          <div className='md:hidden z-50'>
            <button
              onClick={toggleMenu}
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } focus:outline-none`}
              aria-label='Toggle menu'
            >
              {isOpen ? (
                <svg
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              ) : (
                <svg
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 6h16M4 12h16m-7 6h7'
                  />
                </svg>
              )}
            </button>
          </div>

          {/* Menu Links */}
          <div
            className={`${
              isOpen ? 'flex' : 'hidden'
            } md:flex flex-col md:flex-row items-center md:items-center absolute md:relative top-16 md:top-0 left-0 w-full md:w-auto bg-[#2c3e50] md:bg-transparent z-40 py-6 md:py-0 md:space-x-8`}
          >
            <Link
              to='/pricing'
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
            >
              Pricing
            </Link>
            <Link
              to='/faqs'
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
            >
              FAQs
            </Link>
            <Link
              to='/login'
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-medium text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
            >
              Login
            </Link>
            <Link
              to='/register'
              className={`${
                scrolled ? 'bg-white text-[#2563EB]' : 'bg-[#2563EB] text-white'
              } py-2 px-4 rounded-lg font-semibold text-base md:text-lg hover:bg-white hover:text-[#2563EB] transition-colors duration-300 md:ml-4`}
            >
              Start for free
            </Link>
          </div>
        </div>
      </div>

      <div className='h-[100vh] flex items-center justify-center px-5 lg:px-0 mt-16'>
        <div className='max-w-screen-xl bg-white border shadow sm:rounded-lg flex justify-center flex-1'>
          <div className='flex-1 bg-blue-900 text-center hidden md:flex'>
            <div
              className='m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat'
              style={{
                backgroundImage: `url(https://www.tailwindtap.com/assets/common/marketing.svg)`,
              }}
            ></div>
          </div>
          <div className='lg:w-1/2 xl:w-5/12 p-6 sm:p-12'>
            <div className='flex flex-col items-center'>
              <div className='text-center'>
                <h1 className='text-2xl xl:text-4xl font-extrabold text-blue-900 mb-2'>
                  Sign In
                </h1>
                <p className='text-[12px] text-gray-500 mb-6'>
                  Enter your email and password to sign in.
                </p>
              </div>

              {/* Reminder Banner */}
              <div className='bg-red-100 border border-red-400 px-4 py-3 rounded mb-4 text-sm flex items-start'>
                <svg
                  className='w-5 h-5 mr-2 flex-shrink-0 text-red-600'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  viewBox='0 0 24 24'
                >
                  <path d='M12 9v2m0 4h.01M12 2a10 10 0 1 1-10 10A10 10 0 0 1 12 2z' />
                </svg>
                <span>
                  <span className='text-red-600 font-semibold'>Reminder:</span>{' '}
                  Reekap does not store any meeting recordings or data. You are
                  responsible for your organization's data storage.
                  <Link to='/terms' className='text-red-800 font-semibold ml-1'>
                    Read terms.
                  </Link>
                </span>
              </div>

              {errorMessage && (
                <div className='bg-red-100 text-red-700 border border-red-400 px-4 py-3 rounded mb-4'>
                  {errorMessage}
                </div>
              )}

              <form className='w-full flex-1 mt-8' onSubmit={handleSubmit}>
                <div className='mx-auto max-w-xs flex flex-col gap-4'>
                  <input
                    className='w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white'
                    type='email'
                    placeholder='Enter your email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <input
                    className='w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white'
                    type='password'
                    placeholder='Enter your password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <button
                    className='mt-5 tracking-wide font-semibold bg-blue-900 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none'
                    disabled={isLoading}
                  >
                    <span className='ml-3'>
                      {isLoading ? <div className='loading'></div> : 'Sign In'}
                    </span>
                  </button>
                  <button
                    type='button'
                    className='mt-3 tracking-wide font-semibold bg-red-600 text-gray-100 w-full py-4 rounded-lg hover:bg-red-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none'
                    onClick={handleGoogleSignIn}
                  >
                    <svg
                      className='w-6 h-6 -ml-2'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <path d='M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c13 8 27 0 27-16a8.9 8.9 0 0 0-.08-1.32A7.72 7.72 0 0 0 23 3z' />
                    </svg>
                    <span className='ml-3'>Sign In with Google</span>
                  </button>
                  <p className='mt-6 mb-3 text-xs text-gray-600 text-center'>
                    <Link
                      to='/forgot-password'
                      className='text-blue-900 font-semibold'
                    >
                      Forgot password?
                    </Link>
                  </p>
                  <p className='mt-3 text-xs text-gray-600 text-center'>
                    Don't have an account?{' '}
                    <Link
                      to='/register'
                      className='text-blue-900 font-semibold'
                    >
                      Sign up
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
